export const appointmentsJSON = [
    {
      id: 1,
      client: {
        clientId: 101,
        clientName: "John Doe",
      },
      dateTime: "2024-04-10T09:00:00",
      duration: 60,
      status: "confirmado",
      place: "123 Main Street",
      synchronizeGoogleCalendar: false,
      videoconference: {
        tool: "zoom",
        url: "https://zoom.us/j/1234567890",
      },
    },
    {
      id: 2,
      client: {
        clientId: 102,
        clientName: "Jane Smith",
      },
      dateTime: "2024-04-12T14:30:00",
      duration: 45,
      status: "sin confirmar",
      place: "456 Elm Avenue",
      synchronizeGoogleCalendar: true,
      videoconference: {
        tool: "google meet",
        url: "https://meet.google.com/abc-def-ghi",
      },
    },
    {
      id: 3,
      client: {
        clientId: 103,
        clientName: "Alice Johnsonnnnnnnnn",
      },
      dateTime: "2024-04-15T11:00:00",
      duration: 90,
      status: "cancelado",
      place: "789 Oak Street",
      synchronizeGoogleCalendar: true,
      videoconference: {
        tool: "skype",
        url: "skype:alice.johnson?call",
      },
    },
    // Add more objects as needed
  ];


  export const weekDaysJSON = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];