import React, { useState } from "react";

/// Scroll
import Select from "react-select";
import MealCard from "./components/MealCard";
import GlobalAnalysisCard from "./components/GlobalAnalysisCard";
import { Button, Modal } from "react-bootstrap";

const mealTimeExamples = [
  {
    name: "almuerzo",
    time: "12:30",
    timePeriod: "pm",
  },
  {
    name: "cena",
    time: "19:00",
    timePeriod: "pm",
  },
];

const mealNames = [
  "Wake up",
  "Breakfast",
  "Morning Snack",
  "Lunch",
  "Afternoon Snack",
  "Dinner",
  "Bedtime",
];

function sortByTime(mealTimesList) {
  mealTimesList.sort((a, b) => {
    // Convertir los tiempos a números para comparar
    const timeA = parseFloat(a.time.replace(":", "."));
    const timeB = parseFloat(b.time.replace(":", "."));

    // Comparar los tiempos
    return timeA - timeB;
  });

  return mealTimesList;
}

const MealPlanTab = () => {
  const onInit = () => {
    //console.log('lightGallery has been initialized');
  };

  const [activeModal, setActiveModal] = useState(false);
  const [mealTimes, setMealTimes] = useState(mealTimeExamples);

  const [mealTimeName, setMealTimeName] = useState("");
  const [mealTimeTime, setMealTimeTime] = useState("");
  const [selectedMealTime, setSelectedMealTime] = useState(null);
  const [editedMealTime, setEditedMealTime] = useState(null);

  function onEditMealTime() {
    const newMealTime = {
      name: editedMealTime.name,
      time: mealTimeTime,
    };

    let newMealTimes = mealTimes.map((meal) => {
      if (meal.name == editedMealTime.name) {
        return {
          ...editedMealTime,
          time: mealTimeTime,
        };
      }
      return meal;
    });

    newMealTimes = sortByTime(newMealTimes);

    setMealTimes(newMealTimes);
    setEditedMealTime(null);
    setMealTimeTime("");
    setMealTimeName("");
    setActiveModal(false);
  }

  function onAddMealTime() {
    console.log(mealTimeName)
    const newMealTime = {
      name: mealTimeName,
      time: mealTimeTime,
    };

    let newMealTimes = [...mealTimes, newMealTime];
    newMealTimes = sortByTime(newMealTimes);

    setMealTimes(newMealTimes);
    setActiveModal(false);
  }

  function onDeleteMealTime(name) {
    let newMealTimes = mealTimes.filter((m) => m.name != name);
    newMealTimes = sortByTime(newMealTimes);

    setMealTimes(newMealTimes);
    setActiveModal(false);
  }

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-xl-8 col-lg-8 ">
          <div className="row">
            <div className="col-xl-12 col-lg-12 ">
              {mealTimes?.map((meal, index) => (
                <div key={index} className="col-xl-12 col-lg-12 ">
                  <MealCard
                    meal={meal}
                    setEditedMealTime={setEditedMealTime}
                    setActiveEditModal={setActiveModal}
                    onDeleteMealTime={onDeleteMealTime}
                  ></MealCard>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 ">
          <div className="row mb-3">
            <div className="col-xl-8">
              <button
                onClick={() => setActiveModal(true)}
                className="btn btn-info m-auto btn-sm"
              >
                <span className="me-2">
                  {" "}
                  <i className="fa fa-plus" />
                </span>
                Agregar Horario de Comida
              </button>
            </div>

            <div className="col-xl-2">
              <button disabled className="btn btn-light m-auto btn-sm">
                <span className=" text-center">
                  {" "}
                  <i className="fa fa-print" />
                </span>
              </button>
            </div>

            <div className="col-xl-2">
              <button className="btn btn-light m-auto btn-sm">
                <span className=" text-center">
                  {" "}
                  <i className="fa fa-save" />
                </span>
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 col-lg-12 ">
              <GlobalAnalysisCard></GlobalAnalysisCard>
            </div>
          </div>
        </div>
      </div>

      {/* Modal to add a food */}

      <Modal
        size="lg"
        show={activeModal}
        className="fade"
        id="addMoreTraining"
        onHide={() => {
          setEditedMealTime(null);
          setMealTimeName("");
          setMealTimeTime("");
          setActiveModal(false);
        }}
      >
        <div className="modal-content">
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title">
              Agregar/Editar Horario de comida
            </Modal.Title>
            <Button
              variant=""
              onClick={() => {
                setEditedMealTime(null);
                setMealTimeName("");
                setMealTimeTime("");
                setActiveModal(false);
              }}
              type="button"
              className="close"
            >
              <span>×</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group">
                <label for="frutas">
                  Seleccione el nombre del horario de comida y la hora:
                </label>
                <Select
                    disabled={editedMealTime}
                    onChange={(target) => setMealTimeName(target.value)}
                    options={mealNames.map((meal) => ({
                      value: meal,
                      label: meal,
                    }))}
                    // placeholder="Seleccione la comida"
                    style={{
                      lineHeight: "40px",
                      color: "#7e7e7e",
                      paddingLeft: " 15px",
                    }}
                    
                  />
                <div class="input-group">
                  {/* <select
                    onChange={(e) => setMealTimeName(e.target.value)}
                    className="form-control"
                    id="inputGroupSelect04"
                    disabled={editedMealTime}
                  >
                    <option selected>
                      {editedMealTime
                        ? editedMealTime.name.toUpperCase()
                        : "Choose..."}
                    </option>
                    {mealNames.map((meal, index) => (
                      <option value={meal} key={index}>
                        {meal}
                      </option>
                    ))}
                  </select> */}
                  {/* <label>Cantidad</label> */}
                  
                  <input
                    onChange={(e) => setMealTimeTime(e.target.value)}
                    type="time"
                    className="form-control mt-2"
                    placeholder="1"
                    defaultValue={editedMealTime ? editedMealTime.time : ""}
                  />
                </div>
              </div>

              {editedMealTime ? (
                <button
                  onClick={() => onEditMealTime()}
                  className="btn btn-primary"
                >
                  Guardar
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  onClick={() => onAddMealTime()}
                >
                  Agregar
                </button>
              )}
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default MealPlanTab;
