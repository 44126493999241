import React from "react";

const StepTwo = ({ formData, setFormData }) => {
   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevData => ({
         ...prevData,
         [name]: value,
      }));
   };

   const handleFileChange = (e) => {
      const file = e.target.files[0];
      setFormData(prevData => ({
         ...prevData,
         photo: file,
      }));
   };

   return (
      <section>
         <div className="row">
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">License Number</label>
                  <input
                     type="text"
                     name="licenseNumber"
                     className="form-control"
                     placeholder="Enter license number"
                     required
                     value={formData.licenseNumber}
                     onChange={handleInputChange}
                  />
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Upload Photo <span className="required">*</span></label>
                  <input
                     type="file"
                     name="photo"
                     className="form-control"
                     required
                     onChange={handleFileChange}
                  />
               </div>
            </div>
         </div>
      </section>
   );
};

export default StepTwo;
