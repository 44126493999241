import React from "react";
import YogaIcon from "./YogaIcon";
import CyclingIcon from "./CyclingIcon";
import RunningIcon from "./RunningIcon";
import WaterIcon from "./WaterIcon";

export default function WaterAndExcerciseCard() {
  return (
    <div className="row">
      <div className="media align-items-center border border-warning rounded p-3 mb-md-4 mb-3">
        <RunningIcon></RunningIcon>
        <div>
          <h4 className="fs-18 text-black mb-0">Ejercicio</h4>
          <span className="fs-14 text-warning">52 hours, 2min</span>
        </div>
      </div>
      <div className="media align-items-center border border-info rounded p-3 mb-md-4 mb-3">
        <WaterIcon></WaterIcon>
        <div>
          <h4 className="fs-18 text-black mb-0">Agua</h4>
          <span className="fs-14 text-info">23 hours, 45min</span>
        </div>
      </div>
      {/* <div className="media align-items-center border border-secondary rounded p-3">
        <YogaIcon></YogaIcon>
        <div>
          <h4 className="fs-18 text-black mb-0">Yoga</h4>
          <span className="fs-14 text-secondary">16 hours, 2min</span>
        </div>
      </div> */}
    </div>
  );
}
