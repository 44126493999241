import React, {  } from "react";

/// Scroll

import AppointmentInformationCard from "./components/AppointmentInformationCard";
import PersonalhistoryCard from "./components/PersonalHistoryCard";
import FoodHistoryCard from "./components/FoodHistoryCard";
import ClinicHistoryCard from "./components/ClinicHistoryCard";
import FilesCard from "./components/FilesCard";
import FoodDiariesCard from "./components/FoodDiaries";
import FoodBehaviorsCard from "./components/FoodBehaviorsCard";
import ObjectivesCard from "./components/ObjectivesCard";

const InformationTab = () => {
  return (
    <div className="card-body">
      <div className="row">
        <div className="col-xl-6 col-lg-6 ">
          <div className="row">
            <div className="col-xl-12 col-lg-12 ">
              <div className="col-xl-12 col-lg-12 ">
                <AppointmentInformationCard></AppointmentInformationCard>
              </div>
              <div className="col-xl-12 col-lg-12 ">
                <PersonalhistoryCard></PersonalhistoryCard>
              </div>
              <div className="col-xl-12 col-lg-12 ">
                <FoodHistoryCard></FoodHistoryCard>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 ">
          <div className="row">
            <div className="col-xl-12 col-lg-12 ">
              <div className="col-xl-12 col-lg-12 ">
                <FoodDiariesCard></FoodDiariesCard>
              </div>
              <div className="col-xl-12 col-lg-12 ">
                <FoodBehaviorsCard></FoodBehaviorsCard>
              </div>
              <div className="col-xl-12 col-lg-12 ">
                <ClinicHistoryCard></ClinicHistoryCard>
              </div>
              <div className="col-xl-12 col-lg-12 ">
                <ObjectivesCard></ObjectivesCard>
              </div>
              <div className="col-xl-12 col-lg-12 ">
                <FilesCard></FilesCard>
              </div>
            </div>
          </div>
        </div>

        {/* form */}

        {/* <div className="basic-form">
                        <form action="#">
                          <div className="input-group mb-3 input-warning-o">
                            <span className="input-group-text">@</span>

                            <input
                              type="text"
                              className="form-control"
                              placeholder="Username"
                            />
                          </div>
                          <div className="input-group mb-3 input-success-o">
                            <span className="input-group-text">@</span>

                            <input
                              type="text"
                              className="form-control"
                              placeholder="Username"
                            />
                          </div>
                          <div className="input-group mb-3 input-primary">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Recipient's username"
                            />

                            <span className="input-group-text">
                              @example.com
                            </span>
                          </div>

                          <label>Your vanity URL</label>
                          <div className="input-group mb-3  input-success">
                            <span className="input-group-text">
                              https://example.com
                            </span>

                            <input type="text" className="form-control" />
                          </div>

                          <div className="input-group mb-3  input-info">
                            <span className="input-group-text">$</span>

                            <input type="text" className="form-control" />

                            <span className="input-group-text">.00</span>
                          </div>

                          <div className="input-group">
                            <span className="input-group-text">
                              Motivo de consulta
                            </span>

                            <textarea className="form-control"></textarea>
                          </div>
                        </form>
                      </div> */}
        {/* ==================== */}
      </div>
    </div>
  );
};

export default InformationTab;
