import { FC, useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { EventInput } from "@fullcalendar/core";
import {
  convertToApiEvent,
  convertToFullCalendarEvent,
  convertToApiEvents,
} from "./functions-calendar/functions";
// import { ApiEvent } from './interfaces-calendar/interface-calendar';
// import EventModal from './components/event-modal';
import {
  getEvents,
  createEvent,
  updateEvent,
  deleteEvent,
} from "../../services/service";
import "../../assets/css/calendar-style.css";
import EventModal from "./components/event-modal";
import { Button, Modal, Dropdown } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStart, setSelectedStart] = useState("");
  const [selectedEnd, setSelectedEnd] = useState("");
  const [isMultiDay, setIsMultiDay] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const fetchEvents = async () => {
    try {
      const response = await getEvents();
      const apiEvents = response.data.map(convertToApiEvent);
      const fullCalendarEvents = apiEvents.map(convertToFullCalendarEvent);
      setEvents(fullCalendarEvents);
    } catch (err) {
      console.error("Error fetching events:", err);
    }
  };

  useEffect(() => {
    // fetchEvents()
  }, []);

  const handleDateSelect = (selectInfo) => {
    console.log(selectInfo);
    let start = selectInfo.startStr;
    let end = selectInfo.endStr;
    const currentView = selectInfo.view.type;
    if (currentView === "dayGridMonth") {
      end = new Date(new Date(end).getTime() - 60000).toISOString();
    }
    const multiDay = start.split("T")[0] !== end.split("T")[0];
    setSelectedStart(start);
    setSelectedEnd(end);
    setIsMultiDay(multiDay);
    setIsModalOpen(true);
    setSelectedEvent(null);
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event);
    setIsModalOpen(true);
  };

  const handleEventSubmit = async (event) => {
    console.log(event);
    setEvents((prevEvents) => {
      return prevEvents.map((e) => (e.id === event.id ? event : e));
    });
    if (event.id) {
      try {
        console.log("existe")
        const apiEvent = convertToApiEvents(event);
        const fullCalendarEvent = convertToFullCalendarEvent(apiEvent);
        // await updateEvent(apiEvent.eventId, apiEvent);
        setEvents((prevEvents) => {
          return prevEvents.map((e) => (e.id === event.id ? fullCalendarEvent : e));
        });
      } catch (err) {
        new Error("An error occurred while submitting the event.");
      }
    } else {
      try {
        event.id=uuidv4()
        const apiEvent = convertToApiEvents(event);
        const fullCalendarEvent = convertToFullCalendarEvent(apiEvent);
         await createEvent(apiEvent);
        setEvents([...events, fullCalendarEvent]);
        // await fetchEvents();
        console.log("added event");
      } catch (err) {
        new Error("An error occurred while submitting the event.");
      }
    }
    setIsModalOpen(false);
  };

  const handleEventDelete = async () => {
    try {
      if (selectedEvent && selectedEvent.id) {
        await deleteEvent(selectedEvent.id);
        setEvents((prevEvents) =>
          prevEvents.filter((e) => e.id !== selectedEvent.id)
        );
        setIsModalOpen(false);
      }
    } catch (err) {
      new Error("An error occurred while deleting the event.");
    }
  };

  const defaultTimeStart = "09:00";
  const defaultTimeEnd = "10:00";
  const timeStart =
    selectedStart.split("T")[1]?.substr(0, 5) || defaultTimeStart;
  const timeEnd = selectedEnd.split("T")[1]?.substr(0, 5) || defaultTimeEnd;

  return (
    <div className="row bg-white">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        selectable={true}
        selectMirror={false}
        dayMaxEvents={true}
        weekends={true}
        events={events}
        allDaySlot={false}
        select={handleDateSelect}
        eventClick={handleEventClick}
        // businessHours={{
        //   startTime: "02:00:00", // 2 am
        //   endTime: "06:00:00", // 6 am
        // }}
        editable={true}
      />
      {isModalOpen ? (
        <Modal
          size="lg"
          show={isModalOpen}
          className="fade"
          id="addMoreTraining"
          onHide={() => {
            setIsModalOpen(false);
          }}
        >
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title">
              Informacion de la cita medica
            </Modal.Title>
            <Button
              variant=""
              onClick={() => {
                setIsModalOpen(false);
              }}
              type="button"
              className="close"
            >
              <span>×</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <EventModal
              setIsModalOpen={setIsModalOpen}
              start={selectedStart}
              end={selectedEnd}
              isMultiDay={isMultiDay}
              timeStart={timeStart}
              timeEnd={timeEnd}
              selectedEvent={selectedEvent}
              onSubmit={handleEventSubmit}
              onDelete={handleEventDelete}
              onClose={() => setIsModalOpen(false)}
            />
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Calendar;
