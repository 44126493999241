import React from "react";

export default function AppointmentInformationCard() {
  return (
    <div className="card border rounded">
      <div className="card-header border-0">
        <div className="me-auto pr-3">
          <h4 className="text-black fs-20">Informaciones de consulta</h4>
          <p className="fs-13 mb-0">
            Motivación y expectativas para el seguimiento
          </p>
        </div>
      </div>
      <div className="card-body">
        <div className="basic-form">
          <form action="#">
            <div className="input-group mb-2">
              <span className="input-group-text">Motivo de consulta</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Expectativas</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Objetivos Clinicos</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Otras informaciones</span>
              <textarea className="form-control"></textarea>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
