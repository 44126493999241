import React from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function GlobalAnalysisCard() {
  return (
    // <div className="card border  rounded">
    //   <div className="card-header border-0">
    //     <div className="me-auto pr-3">
    //       <h4 className="text-black fs-20">Análisis global</h4>
    //       <p className="fs-13 mb-0">Análisis global de las comidas</p>
    //     </div>
    //   </div>
    //   <div className="card-body">
        <div className="row">
          <div className="col-lg-3">
            <div className="row ">
              <div className="media mb-2 mt-1">
                <div className="media-body d-block text-center">
                  <span className="text-black">1968</span>
                  <span className="text-gray">/1910</span>

                  <p className="read-content-email">
                    <ProgressBar now={60} variant="danger" />
                  </p>
                  <h5 className="my-1 text-black">Energía</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="row ">
              <div className="media mb-2 mt-1">
                <div className="media-body d-block text-center">
                  <span className="text-black">1968</span>
                  <span className=" text-gray">/1910</span>

                  <p className="read-content-email">
                    <ProgressBar now={60} variant="secondary" />
                  </p>
                  <h5 className="my-1 text-black">Grasa</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            {" "}
            <div className="row ">
              <div className="media mb-2 mt-1">
                <div className="media-body d-block text-center">
                  <span className="text-black">1968</span>
                  <span className=" text-gray">/1910</span>

                  <p className="read-content-email">
                    <ProgressBar now={60} variant="info" />
                  </p>
                  <h5 className="my-1 text-black">Hidratos de Carbono</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            {" "}
            <div className="row ">
              <div className="media mb-0 mt-1">
                <div className="media-body d-block text-center">
                  <p className="read-content-email">
                    <span className="text-black">1968</span>
                    <span className=" text-gray">/1910</span>
                    <ProgressBar now={60} variant="green" />
                  </p>

                  <h5 className="my-1 text-black">Proteína</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
    //   </div>
    // </div>
  );
}
