import React, { useContext, useEffect, useState } from "react";

import { ThemeContext } from "../../../context/ThemeContext";

import avatar1 from "../../../images/avatar/1.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaUserAlt, FaPlusCircle } from "react-icons/fa";
import AddClientModal from "./components/AddClientModal";
import { clientsJSON } from "../../../store/json/clients";

const clientStatus=[
  "active",
  "inactive"
]
const ClientsDashboard = () => {
  const { changeBackground } = useContext(ThemeContext);
  const [clientsList, setClientsList] = useState(clientsJSON);

  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  const [largeModal, setLargeModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [dropdownDefaultValue, setDropdownDefaultValue] = useState('Todos')

  function getClientsStatistics(){

    let activeClients=clientsJSON?.filter(client=>client.status=='active').length
    let inactiveClients=clientsJSON?.filter(client=>client.status=='inactive').length

    let activePercentage=(activeClients*100)/clientsJSON?.length
    
    return {
      activeClients:activeClients,
      inactiveClients:inactiveClients,
      activePercentage:activePercentage,
      totalClients:clientsJSON.length
    }
  }

  function filterClients(status,label){
    setDropdownDefaultValue(label)
    if(status=='all'){
      setClientsList(clientsJSON)
      return

    }
    let filteredClients=clientsJSON.filter(client=> client.status==status)

    setClientsList(filteredClients)
  }

  function addClient(client) {
    setClientsList([...clientsList, client]);
  }

  return (
    <>
      <div className="row">
        {/* Left Column for clients */}
        <div className="col-xl-8 col-xxl-8">
          <div className="row ">
            {/* 1st left column up */}
            <div className="col-xl-12">
              <div className="card plan-list">
                <div className="card-header d-sm-flex d-block pb-0 border-0">
                  <div className="me-auto pe-3">
                    <h4 className="text-black fs-20">Tus clientes</h4>
                    <p className="fs-13 mb-0 text-black">
                      Ve tus próximas consultas y clientes
                    </p>
                  </div>
                  <Dropdown className="mt-sm-0 mt-3">
                    <Dropdown.Toggle
                      variant=""
                      as="button"
                      className="btn rounded border text-black border-light dropdown-toggle"
                    >
                      {dropdownDefaultValue}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-right">
                    <Dropdown.Item onClick={()=>{filterClients("all","Todos")}}>Todos</Dropdown.Item>
                      <Dropdown.Item onClick={()=>{filterClients("active","Activos")}}>Activos</Dropdown.Item>
                      <Dropdown.Item onClick={()=>{filterClients("inactive", "Inactivos")}}>Inactivos</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    as="a"
                    to="/workout-statistic"
                    className="m-2 bg-primary text-white text-center fs-10 d-block rounded"
                    data-toggle="modal"
                    data-target="#addMoreTraining"
                    onClick={() => setShowModal(true)}
                  >
                    + Agregar cliente
                  </Button>
                </div>

                <div className="card-body">
                  {/* ================================================================================ */}
                  {/* Empieza Div de cada cita */}
                  <div className="row">
                    {/* div de cada cliente */}
                    {clientsList.map((client, index) => (
                      <div key={index} className="col-xl-6">
                        <div className="">
                          <div className="d-flex px-3 pt-3 list-row flex-wrap align-items-center mb-2">
                            <div className=" me-3 mb-3 rounded-md media me-2">
                              <img
                                alt=""
                                width="50"
                                src={
                                  client.profileImg
                                    ? URL.createObjectURL(client.profileImg)
                                    : "https://cdn-icons-png.flaticon.com/512/3135/3135768.png"
                                }
                              />
                            </div>
                            <div className="col ">
                              <h5 className="fs-18 ">
                                <Link
                                  to="/workout-statistic"
                                  className="text-black"
                                >
                                  {client.clientName}
                                </Link>
                              </h5>
                              <span className="text-info font-w300 mb-3">
                                {client.occupation}
                              </span>
                            </div>

                            <Dropdown className="dropdown mb-3">
                              <Dropdown.Toggle
                                as="button"
                                variant=""
                                className="btn rounded border-light icon-false"
                              >
                                <svg
                                  width={6}
                                  height={26}
                                  viewBox="0 0 6 26"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3Z"
                                    fill="#585858"
                                  />
                                  <path
                                    d="M6 13C6 14.6569 4.65685 16 3 16C1.34315 16 0 14.6569 0 13C0 11.3431 1.34315 10 3 10C4.65685 10 6 11.3431 6 13Z"
                                    fill="#585858"
                                  />
                                  <path
                                    d="M6 23C6 24.6569 4.65685 26 3 26C1.34315 26 0 24.6569 0 23C0 21.3431 1.34315 20 3 20C4.65685 20 6 21.3431 6 23Z"
                                    fill="#585858"
                                  />
                                </svg>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu-right">
                                <Dropdown.Item>Editar Perfil</Dropdown.Item>
                                <Dropdown.Item>Consultar perfil</Dropdown.Item>
                                <Dropdown.Item>Eliminar cliente</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* ================================================================================ */}
                    {/* Termina Div de cada cita */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column for filtering clients */}
        <div className="col-xl-4 col-md-6">
          <div className="card">
            <div className="card-header flex-wrap border-0 pb-0">
              <h4 className="text-black fs-20 mb-3">Informacion General</h4>
            </div>
            <div className="card-body">
              <div className="d-flex mb-sm-5 mb-3">
                <div className="d-inline-block relative donut-chart-sale me-3">
                  <svg className="peity" height={90} width={90}>
                    <path
                      d="M 45 0 A 45 45 0 0 1 90 45 L 79 45 A 34 34 0 0 0 45 11"
                      data-value={2}
                      fill="rgb(255, 148, 50)"
                    />
                    <path
                      d="M 90 45 A 45 45 0 1 1 44.99999999999999 0 L 44.99999999999999 11 A 34 34 0 1 0 79 45"
                      data-value={6}
                      fill="rgba(236, 236, 236, 1)"
                    />
                  </svg>

                  <small>
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip3)">
                        <path
                          d="M0.988957 17.0741C0.328275 17.2007 -0.104585 17.8386 0.0219821 18.4993C0.133361 19.0815 0.644693 19.4865 1.21678 19.4865C1.29272 19.4865 1.37119 19.4789 1.44713 19.4637L6.4592 18.5018C6.74524 18.4461 7.0009 18.2917 7.18316 18.0639L9.33481 15.3503L8.61593 14.9832C8.08435 14.7149 7.71474 14.2289 7.58818 13.6391L5.55804 16.1983L0.988957 17.0741Z"
                          fill="#FF9432"
                        />
                        <path
                          d="M18.84 6.49306C20.3135 6.49306 21.508 5.29854 21.508 3.82502C21.508 2.3515 20.3135 1.15698 18.84 1.15698C17.3665 1.15698 16.1719 2.3515 16.1719 3.82502C16.1719 5.29854 17.3665 6.49306 18.84 6.49306Z"
                          fill="#FF9432"
                        />
                        <path
                          d="M13.0179 3.15677C12.7369 2.8682 12.4762 2.75428 12.1902 2.75428C12.0864 2.75428 11.9826 2.76947 11.8712 2.79479L7.29203 3.88073C6.6592 4.03008 6.26937 4.66545 6.41872 5.29576C6.54782 5.83746 7.02877 6.20198 7.56289 6.20198C7.65404 6.20198 7.74514 6.19185 7.8363 6.16907L11.7371 5.24513C11.9902 5.52611 13.2584 6.90063 13.4888 7.14364C11.8763 8.87002 10.2639 10.5939 8.65137 12.3202C8.62605 12.3481 8.60329 12.3759 8.58049 12.4038C8.10966 13.0037 8.25397 13.9454 8.96275 14.3023L13.9064 16.826L11.3397 20.985C10.9878 21.5571 11.165 22.3064 11.7371 22.6608C11.9371 22.7848 12.1573 22.843 12.375 22.843C12.7825 22.843 13.1824 22.638 13.4128 22.2659L16.6732 16.983C16.8529 16.6919 16.901 16.34 16.8074 16.0135C16.7137 15.6844 16.4884 15.411 16.1821 15.2566L12.8331 13.553L16.3543 9.78636L19.0122 12.0393C19.2324 12.2266 19.5032 12.3177 19.7716 12.3177C20.0601 12.3177 20.3487 12.2114 20.574 12.0038L23.6243 9.16112C24.1002 8.71814 24.128 7.97392 23.685 7.49803C23.4521 7.24996 23.1383 7.12339 22.8244 7.12339C22.5383 7.12339 22.2497 7.22717 22.0245 7.43728L19.7412 9.56107C19.7386 9.56361 14.0178 4.18196 13.0179 3.15677Z"
                          fill="#FF9432"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip3">
                          <rect width={24} height={24} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </small>
                </div>
                <div>
                  <h4 className="fs-18 text-black">Clientes Activos ({getClientsStatistics()?.activePercentage}%)</h4>
                  <span>{getClientsStatistics()?.activeClients+'/'+getClientsStatistics()?.totalClients}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddClientModal
          addClient={addClient}
          setShowModal={setShowModal}
          showModal={showModal}
        ></AddClientModal>
      </div>
    </>
  );
};

export default ClientsDashboard;
