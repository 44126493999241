import React from "react";

/// Scroll

import LastMeasurementsCard from "./components/LastMeasurementsCard";
import ClinicHistoryCard from "./components/LastMeasurementsCard";
import FilesCard from "./components/FilesCard";
import FoodDiariesCard from "./components/FoodDiaries";
import FoodBehaviorsCard from "./components/FoodBehaviorsCard";
import ObjectivesCard from "./components/ObjectivesCard";
import AnthropometricInformation from "./components/AnthropometricInformation";
import AnalyticsDataCard from "./components/AnalyticsDataCard";
import BodyCompositionCard from "./components/BodyCompositionCard";
import { Col, ListGroup, Row, Tab } from "react-bootstrap";
import ApexLine from "./components/Line";

const MeasuresTab = () => {
  return (
    <div className="card-body">
      {/* <div className="basic-list-group">
        <Row>
          <Tab.Container defaultActiveKey="#home">
            <Col lg="6" xl="2">
              <ListGroup className="mb-4" id="list-tab">
                <ListGroup.Item action href="#home">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-auto">
                      <p >exxx</p>
                    </div>
                    <div class="col">
                      <p class="">
                        kg
                      </p>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item action href="#profile">
                  Profile
                </ListGroup.Item>
                <ListGroup.Item action href="#messages">
                  Messages
                </ListGroup.Item>
                <ListGroup.Item action href="#settings">
                  Settings
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col lg="6" xl="10">
              <Tab.Content>
                <Tab.Pane eventKey="#home">
                  <h4 className="mb-4">Ultimas Mediciones de</h4>
                  <p></p>
                </Tab.Pane>
                <Tab.Pane eventKey="#profile">
                  <h4 className="mb-4">Ultimas Mediciones de</h4>
                  <p></p>
                </Tab.Pane>
                <Tab.Pane eventKey="#messages">
                  <h4 className="mb-4">Ultimas Mediciones de</h4>
                  <p></p>
                </Tab.Pane>
                <Tab.Pane eventKey="#settings">
                  <h4 className="mb-4">Ultimas Mediciones de</h4>
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-body pb-0">
                        <ApexLine />
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Tab.Container>
        </Row>
      </div> */}

      <div className="row">
        <div className="col-xl-4 col-lg-4 ">
          <div className="row">
            <div className="col-xl-12 col-lg-12 ">
              <div className="col-xl-12 col-lg-12 ">
                <AnthropometricInformation></AnthropometricInformation>
              </div>

              <div className="col-xl-12 col-lg-12 ">
                <AnalyticsDataCard></AnalyticsDataCard>
              </div>

              <div className="col-xl-12 col-lg-12 ">
                <BodyCompositionCard></BodyCompositionCard>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-lg-8 ">
          <div className="row">
            <div className="col-xl-12 col-lg-12 ">
              <LastMeasurementsCard></LastMeasurementsCard>
            </div>
            {/* <div className="col-xl-12 col-lg-12 ">
                <FoodDiariesCard></FoodDiariesCard>
              </div> */}
            {/* <div className="col-xl-12 col-lg-12 ">
                <FoodBehaviorsCard></FoodBehaviorsCard>
              </div> */}
            {/* <div className="col-xl-12 col-lg-12 ">
                <ClinicHistoryCard></ClinicHistoryCard>
              </div> */}
            <div className="col-xl-12 col-lg-12 ">
              <ObjectivesCard></ObjectivesCard>
            </div>
            <div className="col-xl-12 col-lg-12 ">
              <FilesCard></FilesCard>
            </div>
          </div>
        </div>

        {/* form */}

        {/* <div className="basic-form">
                        <form action="#">
                          <div className="input-group mb-3 input-warning-o">
                            <span className="input-group-text">@</span>

                            <input
                              type="text"
                              className="form-control"
                              placeholder="Username"
                            />
                          </div>
                          <div className="input-group mb-3 input-success-o">
                            <span className="input-group-text">@</span>

                            <input
                              type="text"
                              className="form-control"
                              placeholder="Username"
                            />
                          </div>
                          <div className="input-group mb-3 input-primary">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Recipient's username"
                            />

                            <span className="input-group-text">
                              @example.com
                            </span>
                          </div>

                          <label>Your vanity URL</label>
                          <div className="input-group mb-3  input-success">
                            <span className="input-group-text">
                              https://example.com
                            </span>

                            <input type="text" className="form-control" />
                          </div>

                          <div className="input-group mb-3  input-info">
                            <span className="input-group-text">$</span>

                            <input type="text" className="form-control" />

                            <span className="input-group-text">.00</span>
                          </div>

                          <div className="input-group">
                            <span className="input-group-text">
                              Motivo de consulta
                            </span>

                            <textarea className="form-control"></textarea>
                          </div>
                        </form>
                      </div> */}
        {/* ==================== */}
      </div>
    </div>
  );
};

export default MeasuresTab;
