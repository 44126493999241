import React from "react";

export default function ClinicHistoryCard() {
  return (
    <div className="card border  rounded">
      <div className="card-header border-0">
        <div className="me-auto pr-3">
          <h4 className="text-black fs-20">Historia clínica</h4>
          <p className="fs-13 mb-0">
          Patologías, medicación y antecedentes personales y familiares
          </p>
        </div>
      </div>
      <div className="card-body">
        <div className="basic-form">
          <form action="#">
            <div className="input-group mb-2">
              <span className="input-group-text">Patologias</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Medicacion</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Antecedentes personales</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Antecedentes familiares</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Otras informaciones</span>
              <textarea className="form-control"></textarea>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
