import React from "react";
import { Dropdown } from "react-bootstrap";
import profile from "../../../images/profile/profile.png";
import { FcGoogle } from "react-icons/fc";


const BASE_URL = 'http://localhost:8000';



export default function ProfilePage() {

  function handleCredentialResponse(response) {
    console.log("Encoded JWT ID token: " + response.credential);
  }

  function onLinkAdsAccount(service) {

    var redirectUrl=""

    if(service === "google_client"){
        redirectUrl=`${BASE_URL}/google/link_account`;
    }
    else if(service === "meta"){
        redirectUrl = `${BASE_URL}/facebook/link_account`;
    }
    else{
        return;
    }

    const currentUrl = window.location.href;
    try {
        window.location.href = redirectUrl
    } catch (error) {
        console.log("error")
        // window.location.href = currentUrl;
    }
}


  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={profile}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">
                      Alejandro Barrantes Castro
                    </h4>
                    <p>Fullstack Developer</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">ale13bc@outlook.com</h4>
                    <p>Email</p>
                  </div>
                  <Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-user-circle text-primary me-2" />
                        View profile
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-users text-primary me-2" />
                        Add to close friends
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-plus text-primary me-2" />
                        Add to group
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-ban text-primary me-2" />
                        Block
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ======================================================================== */}
        {/* Datos Personales */}

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="pt-3">
                  <div className="settings-form">
                    <h4 className="text-primary">Datos Personales</h4>
                    <form onSubmit={(e) => e.preventDefault()}>
                    
                      {/* ==================================================== */}
                      <div className="row">
                        <div className="form-group mb-3 col-md-3">
                          <label className="form-label">Nacimiento</label>
                          <input
                            type="date"
                            placeholder="fecha nacimiento"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label className="form-label">Genero</label>
                          <select
                            className="form-control"
                            id="inputState"
                            defaultValue="option-1"
                          >
                            <option value="option-1">Escoger...</option>
                            <option value="option-2">Masculino</option>
                            <option value="option-3">Femenino</option>
                            <option value="option-4">No Binario</option>
                            <option value="option-4">Transformer</option>
                          </select>
                        </div>

                        <div className="form-group mb-3 col-md-3">
                          <label className="form-label">Telefono movil</label>
                          <input
                            type="number"
                            placeholder="telefono"
                            className="form-control"
                          />
                        </div>

                        <div className="form-group mb-3 col-md-3">
                          <label className="form-label">Telefono fijo</label>
                          <input
                            type="number"
                            placeholder="telefono"
                            className="form-control"
                          />
                        </div>
                      </div>

                      {/* ==================================================== */}
                      {/* ==================================================== */}

                      <div className="form-group mb-3">
                        <label className="form-label">Direccion</label>
                        <input
                          type="text"
                          placeholder="1234 Main St"
                          className="form-control"
                        />
                      </div>

                      {/* ==================================================== */}

                      <div className="form-group mb-3">
                        <label className="form-label">Direccion 2</label>
                        <input
                          type="text"
                          placeholder="Apartment, studio, or floor"
                          className="form-control"
                        />
                      </div>

                      {/* ==================================================== */}

                      <div className="row">
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">Provincia</label>
                          <select
                            className="form-control"
                            id="inputState"
                            defaultValue="option-1"
                          >
                            <option value="option-1">Escoger...</option>
                            <option value="option-2">Option 1</option>
                            <option value="option-3">Option 2</option>
                            <option value="option-4">Option 3</option>
                          </select>
                        </div>
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">Distrito</label>
                          <select
                            className="form-control"
                            id="inputState"
                            defaultValue="option-1"
                          >
                            <option value="option-1">Escoger...</option>
                            <option value="option-2">Option 1</option>
                            <option value="option-3">Option 2</option>
                            <option value="option-4">Option 3</option>
                          </select>
                        </div>
                        <div className="form-group mb-3 col-md-4">
                          <label className="form-label">Codigo Postal</label>
                          <input
                            type="number"
                            placeholder="Codigo postal"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <button className="btn btn-primary" type="submit">
                        Guardar
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* ======================================================================== */}
        {/* Configuracion de la cuenta */}

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="pt-3">
                  <div className="settings-form">
                    <h4 className="text-primary">Configuración de la cuenta</h4>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row mt-3">
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Correo electrónico</label>
                          <input
                            type="email"
                            placeholder="Email"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label className="form-label">Contraseña</label>
                          <input
                            type="password"
                            placeholder="contraseña"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <button className="btn btn-primary" type="submit">
                        Cambiar contraseña
                      </button>
                      <button onClick={()=>onLinkAdsAccount("google_client")} className="btn btn-light" type="submit">
                          Link with google< FcGoogle size={20}/>
                      </button>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ======================================================================== */}
      </div>
    </>
  );
}
