import React, { useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import menu04 from "../../../images/menus/4.png";
import testimonial01 from "../../../images/testimonial/1.jpg";

/// Scroll
import { Button, Nav, Tab } from "react-bootstrap";

import profileImg from "./../../../images/profile/profile.png";
import InformationTab from "./components/Information";
import MeasuresTab from "./components/Measures";
import MealPlanTab from "./components/MealPlanTab";
import AnalysisTab from "./components/AnalysisTab";
import { appointmentsJSON } from "../../../store/json/appointments";
import { clientsJSON } from "../../../store/json/clients";

const AppointmentDashboard = () => {
  const [addDietMenu, setAddDietMenu] = useState(false);
  const [activesigleMenu, setActivesigleMenu] = useState(false);
  const [tabComponent, setTabComponent] = useState(InformationTab);
  const { id } = useParams();

  const [appointment, setAppointment] = useState(appointmentsJSON.find(app=>app.id==id))
  const client=clientsJSON.find(client=> client.clientId==appointment.client.clientId)

  const data = [
    {
      title: "Fresh or Frozen (No Sugar Added) Fruits",
      img: menu04,
      des: "   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip",
      time: "4-6",
      review: "658",
      ingridients: 5,
      author: { name: "Andrew Sceenshaver", img: testimonial01 },
    },
  ];

  function tabHandler(tab) {
    if (tab == "information") {
      setTabComponent(<InformationTab></InformationTab>);
    }
    if (tab == "measures") {
      setTabComponent(<MeasuresTab></MeasuresTab>);
    }
    if (tab == "mealPlan") {
      setTabComponent(<MealPlanTab></MealPlanTab>);
    }
    if (tab == "analysis") {
      setTabComponent(<AnalysisTab></AnalysisTab>);
    }
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-sm-flex d-block pb-0 border-0">
                  <div className="me-auto pr-3 mb-sm-0 mb-3">
                    <h4 className="text-black fs-20">Consulta de nutrición</h4>
                    <p className="fs-13 mb-0 text-black">
                      Consulta y registra informaciones de tu cliente y crea
                      fácilmente tu plan de alimentación.
                    </p>
                  </div>
                </div>
                <div
                  className="card-body loadmore-content pb-2 dz-scroll"
                  id="DietMenusContent"
                >
                  <div className="row">
                    <div className="d-md-flex d-block menu-list" key={"i"}>
                      <Link to="ecom-product-detail.html">
                        <img
                          className="rounded me-2 mb-md-0 mb-3"
                          src={profileImg}
                          alt=""
                          width={80}
                        />
                      </Link>
                      <div className="col-lg-12 pl-0 ">
                        <div className="d-flex mb-2 ms-4">
                          <h6 className="fs-20 font-w700 me-8">
                            <Link
                              to="ecom-product-detail.html"
                              className="text-black"
                            >
                              {client?.clientName}
                            </Link>
                          </h6>
                        </div>
                        <div className="d-flex mb-3 ">
                          <div className="ms-4">
                            <i className="las la-briefcase scale5 me-2" />
                            <span className="fs-14 text-black text-nowrap font-w500">
                            {client?.occupation}
                            </span>
                          </div>
                          <div className="ms-4">
                            <i className="las la-phone scale5 me-2" />
                            <span className="fs-14 text-black text-nowrap font-w500">
                            {client?.phoneNumber}
                            </span>
                          </div>
                          <div className="ms-4">
                          <i className="las la-calendar scale5 me-2" />
                            <span className="fs-14 text-black text-nowrap font-w500">
                            {client?.birthDate} ({client?.age} años)
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <Button variant="outline-dark btn-sm m-2">Más Información</Button>
                  {/* <button className="btn btn-dark btn-sm m-2 outline" type="submit">Más Información</button> */}
                    {/* <Button
                      to={"#"}
                      //   onClick={() => setActivesigleMenu(i)}
                      variant=""
                      data-toggle="modal"
                      data-target="#aAddDietMenus"
                      className="rounded border text-primary border-primary plus-icon btn-xs m-auto mb-2"
                    >
                      Más Información
                    </Button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* =========================================================================== */}
        <div className="col-xl-12">
          <div className="card">
            <Tab.Container defaultActiveKey="breakfast">
              <div className="card-header d-sm-flex d-block pb-0 border-0 overflow-y-scroll">
                <div className="card-action card-tabs mt-3 mt-sm-0 mt-3 mb-sm-0 mb-3 mt-sm-0">
                  <Nav as="ul" className="nav nav-tabs" role="tablist">
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        onClick={() => tabHandler("information")}
                        data-toggle="tab"
                        eventKey="breakfast"
                      >
                        Informaciones
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className="nav-item">
                      <Nav.Link onClick={()=>tabHandler("measures")} eventKey="lunch">Acompañamiento</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        onClick={() => tabHandler("measures")}
                        eventKey="lunch"
                      >
                        Mediciones
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        onClick={() => tabHandler("mealPlan")}
                        eventKey="plan"
                      >
                        Plan Alimentación
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link onClick={() => tabHandler("analysis")} eventKey="analysis">Analisis</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link eventKey="dinner">Entregables</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <Link
                  to="/diet-food-menu"
                  className="btn btn-primary btn-md rounded d-none d-lg-block ms-0 ms-md-5"
                >
                  Finalizar Consulta
                </Link>
              </div>
            </Tab.Container>

            {tabComponent}
          </div>
        </div>

        {/* ============================================================================= */}
        <div className="col-xl-3 col-xxl-4"></div>
      </div>
    </Fragment>
  );
};

export default AppointmentDashboard;
