import React from "react";

const StepFour = ({ formData, setFormData }) => {
   const daysOfWeek = [
      "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
   ];

   const handleCheckboxChange = (day) => {
      const updatedWorkDays = { ...formData.workDays, [day]: !formData.workDays[day] };
      setFormData("workDays", updatedWorkDays);
   };

   const handleTimeChange = (e) => {
      const { name, value } = e.target;
      setFormData(name, value);
   };

   return (
      <section>
         <div className="row">
            <div className="col-lg-12 mb-2">
               <label className="text-label d-block">Work Days <span className="required">*</span></label>
               <div className="d-flex flex-wrap">
                  {daysOfWeek.map((day) => (
                     <div key={day} className="form-check form-check-inline">
                        <input
                           className="form-check-input"
                           type="checkbox"
                           id={`day-${day}`}
                           checked={formData.workDays && formData.workDays[day]}
                           onChange={() => handleCheckboxChange(day)}
                        />
                        <label className="form-check-label" htmlFor={`day-${day}`}>
                           {day}
                        </label>
                     </div>
                  ))}
               </div>
            </div>
            <label className="text-label d-block"> Work Hours: </label>
            <div className="col-lg-6 mb-2">
            <label className="text-label d-block"> Start Time <span className="required">*</span></label>
               <input
                  type="time"
                  name="startTime"
                  className="form-control"
                  required
                  value={formData.startTime || ''}
                  onChange={handleTimeChange}
               />
            </div>
            <div className="col-lg-6 mb-2">
            <label className="text-label d-block"> End Time <span className="required">*</span></label>
               <input
                  type="time"
                  name="endTime"
                  className="form-control"
                  value={formData.endTime || ''}
                  onChange={handleTimeChange}
                  required
               />
            </div>
         </div>
      </section>
   );
};

export default StepFour;
