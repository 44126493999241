import React from "react";
import { Button } from "react-bootstrap";

export default function ObjectivesCard() {
  return (
    <div className="card border  rounded">
      <div className="card-header border-0">
        <div className="me-auto pr-3">
          <h4 className="text-black fs-20">Objetivos</h4>
          <p className="fs-13 mb-0">
          Próximos objetivos que tu cliente desea alcanzar
          </p>
        </div>
      </div>
      <div className="card-body"></div>
    </div>
  );
}
