import React, {  useEffect, useReducer, useState } from "react";
//import Metismenu from "metismenujs";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from 'react-bootstrap/Collapse';

/// Menu
import { Link, NavLink } from "react-router-dom";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
// import { ThemeContext } from "../../../context/ThemeContext";
import {MenuList} from './Menu';

///
 import drump from "../../../images/card/drump.png";

 const reducer = (previousState, updatedState) => ({
   ...previousState,
   ...updatedState,
 });
 
 const initialState = {
   active : "",
   activeSubmenu : "",
 }


//  function handleChange(value){
//    //   const att = value.target.getAttribute("heartatt");
//    //  const att = value.target.setAttribute("class",'heart-blast');
//    //   value.target.setAttribute("class",'heart-blast');
//       //   value.target.classList.add('heart-blast');
//       //   value.target.classList.toggle('heart-blast');
   
      
//    //  console.log(att, 'check');
//  }

 const SideBar = () => {   
   const date = new Date();
   // const [heartBtn, setHeartBtn] = useState();
   const [state, setState] = useReducer(reducer, initialState);	
    //For scroll
   const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)

   const handleMenuActive = status => {		
         setState({active : status});        
         if(state.active === status){           
            setState({active : ""});
         }     
   }
   const handleSubmenuActive = (status) => {        
      setState({activeSubmenu : status})
      if(state.activeSubmenu === status){
         setState({activeSubmenu : ""})           
      }
      
   }

      /// Path
      let path = window.location.pathname;
      path = path.split("/");
      path = path[path.length - 1];

      useEffect(() => {
         MenuList.forEach((data) => {
           data.content?.forEach((item) => {        
             if(path === item.to){         
               setState({active : data.title})          
             }
             item.content?.forEach(ele => {
               if(path === ele.to){
                 setState({activeSubmenu : item.title, active : data.title})
               }
             })
           })
       })
       },[path]);
      
      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <ul className="metismenu" id="menu">
                  {MenuList.map((data, index)=>{
                     let menuClass = data.classsChange;
                        if(menuClass === "menu-title"){
                           return(
                              <li className={menuClass}  key={index} >{data.title}</li>
                           )
                        }else{
                        return(				
                           <li className={` ${ state.active === data.title ? 'mm-active' : ''} ${data.to === path ? 'mm-active' : ''}`}
                              key={index} 
                           >
                              
                              {data.content && data.content.length > 0 ?
                                 <>
                                    <Link to={"#"} 
                                       className="has-arrow"
                                       onClick={() => {handleMenuActive(data.title)}}
                                    >								
                                       {data.iconStyle}
                                       <span className="nav-text">{data.title}
                                          
                                       </span>
                                    </Link>
                                 
                                    
                                    <Collapse in={state.active === data.title ? true :false}>
                                       <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                          {data.content && data.content.map((data,index) => {						
                                             return(	
                                                <li key={index}
                                                   className={`${ state.activeSubmenu === data.title ? "mm-active" : ""} ${data.to === path ? 'mm-active' : ''}`}                                    
                                                >
                                                   {data.content && data.content.length > 0 ?
                                                      <>
                                                         <NavLink to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
                                                            onClick={() => { handleSubmenuActive(data.title)}}
                                                         >
                                                            {data.title} 
                                                         </NavLink>
                                                         <Collapse in={state.activeSubmenu === data.title ? true :false}>
                                                            <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                                               {data.content && data.content.map((data,index) => {
                                                               return(	                                                                  
                                                                  <li key={index}>
                                                                     <Link className={`${path === data.to ? "mm-active" : ""}`} to={data.to}>{data.title}</Link>
                                                                  </li>                                                                  
                                                               )
                                                               })}
                                                            </ul>
                                                         </Collapse>
                                                      </>
                                                   :
                                                   <Link to={data.to} className={`${data.to === path ? 'mm-active' : ''}`}>
                                                      {data.title} <span className="badge badge-xs badge-danger ms-2">{data.update}</span>
                                                   </Link>
                                                   }                                                   
                                                </li>
                                             )
                                          })}
                                       </ul>
                                    </Collapse>
                                 </>
                              :
                                 <NavLink  to={data.to} >
                                    {data.iconStyle}
                                    <span className="nav-text">{data.title}</span>
                                 </NavLink>
                              }        
                           </li>	
                        )
                     }
                  })}          
               </ul>	
                  {/* <div className="drum-box mt-5">
                     <img src={drump} alt="" />
                     <p className="fs-18 font-w500 mb-4">
                        Start Plan Your Workout
                     </p>
                     <Link className to={"./personal-record"}>
                        Check schedule
                        <svg
                           className="ms-3"
                           width={6}
                           height={12}
                           viewBox="0 0 6 12"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path d="M0 12L6 6L0 0" fill="#BCD7FF" />
                        </svg>
                     </Link>
                  </div> */}

                  <div className="copyright">
                     <p>
                        <strong>Integra Health Admin Dashboard</strong> © {date.getFullYear()} All Rights Reserved
                     </p>
                     <p>
                        Made with <span 
                           className="heart"
                           // className={`heart ${heartBtn ? " heart-blast" : ""}`} 
                           onClick={(e)=>{
                              // handleChange(e) ; 
                              // setHeartBtn(!heartBtn)
                              e.target.classList.toggle('heart-blast')
                           }}
                           // heartatt="box"
                           
                        /> by XSolutions
                     </p>
                  </div>
            </PerfectScrollbar>
         </div>
      );
   
}

export default SideBar;
