
export function calculateNutritionalSummary(foodList) {
    // Initialize variables to hold total values
    let totalEnergy = 0;
    let totalFat = 0;
    let totalCarbos = 0;
    let totalProtein = 0;

    // Calculate total values by iterating through the food list
    foodList.forEach(food => {
        totalEnergy += food.quantity * food.energy;
        totalFat += food.quantity * food.fat;
        totalCarbos += food.quantity * food.carbos;
        totalProtein += food.quantity * food.protein;
    });

    // Calculate percentage values
    const totalWeight = foodList.reduce((total, food) => total + food.weight, 0);
    const energyPercentage = (totalEnergy / totalWeight) * 100;
    const fatPercentage = (totalFat / totalWeight) * 100;
    const carbosPercentage = (totalCarbos / totalWeight) * 100;
    const proteinPercentage = (totalProtein / totalWeight) * 100;

    // Create and return summary object
    const summary = {
        totalEnergy: totalEnergy.toFixed(2),
        totalFat: totalFat.toFixed(2),
        totalCarbos: totalCarbos.toFixed(2),
        totalProtein: totalProtein.toFixed(2),
        energyPercentage: energyPercentage.toFixed(2),
        fatPercentage: fatPercentage.toFixed(2),
        carbosPercentage: carbosPercentage.toFixed(2),
        proteinPercentage: proteinPercentage.toFixed(2)
    };

    return summary;
}