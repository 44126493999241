import { formatError, login, runLogoutTimer, saveTokenInLocalStorage, signUp, confirmEmail, validEmail } from '../../services/AuthService';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const CONFIRMED_EMAIL_ACTION = '[signup action] confirmed email';
export const FAILED_EMAIL_ACTION = '[signup action] failed email';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';

export function signupAction(name, lastname, email, password, navigate) {
  return (dispatch) => {
    signUp(email, password, name, lastname)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(
          dispatch,
          response.data.expires_in * 1000,
          //history,
        );
        dispatch(confirmedSignupAction(response.data));
        navigate('/dashboard');
        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function confirmMail(email, navigate) {
  return (dispatch) => {
    validEmail(email)
      .then((response) => {
        if (!response.data) {
          confirmEmail(email)
            .then(() => {
              navigate('/login');
            })
            .catch((error) => {
              const errorMessage = formatError(error.response.data);
              dispatch(signupFailedAction(errorMessage));
            });
        } else {
          const errorMessage = formatError('EMAIL_EXISTS');
          dispatch(signupFailedAction(errorMessage));
        }
      })
  };
}


export function Logout(navigate) {
  localStorage.removeItem('userDetails');
  navigate('/login');
  //history.push('/login');

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, navigate) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(
          dispatch,
          response.data.expires_in * 1000,
          navigate,
        );
        dispatch(loginConfirmedAction(response.data));
        navigate('/dashboard');
      })
      .catch((error) => {
        //console.log('error');
        const errorMS = error.response.data.detail;
        const errorMessage = formatError(errorMS);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}


export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}


export const navtoggle = () => {
  return {
    type: 'NAVTOGGLE',
  };
};