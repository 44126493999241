import React from "react";
import { Button } from "react-bootstrap";

export default function FilesCard() {
  return (
    <div className="card border  rounded">
      <div className="card-header border-0">
        <div className="me-auto pr-3">
          <h4 className="text-black fs-20">Archivos</h4>
          <p className="fs-13 mb-0">
          Archivos adjuntos del cliente
          </p>
        </div>
        <Button
        size="sm"
          to="/diet-food-menu"
          className="btn btn-info rounded d-none d-lg-block ms-0 ms-md-5"
        >
          Adjuntar archivo
        </Button>
      </div>
      <div className="card-body"></div>
    </div>
  );
}
