import React, { useState } from 'react';

const StepOne = ({ formData, setFormData, onStepChange }) => {
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    lastname: ''
  });

  const validateAndNext = () => {
    let error = false;
    const errorObj = { ...errors };

    if (!formData.email) {
      errorObj.email = 'Email is required';
      error = true;
    }

    if (!formData.password) {
      errorObj.password = 'Password is required';
      error = true;
    }

    if (!formData.confirmPassword) {
      errorObj.confirmPassword = 'Confirm password is required';
      error = true;
    }

    if (formData.password !== formData.confirmPassword) {
      errorObj.confirmPassword = 'Passwords do not match';
      error = true;
    }

    if (!formData.name) {
      errorObj.name = 'First name is required';
      error = true;
    }

    if (!formData.lastname) {
      errorObj.lastname = 'Last name is required';
      error = true;
    }

    setErrors(errorObj);

    if (!error) {
      onStepChange(); // This should move to the next step
    }
  };

  // Function to update the formData state in the Wizard component
  const handleChange = (field, value) => {
    // Clear field error on change
    if (errors[field]) {
      setErrors({ ...errors, [field]: '' });
    }
    setFormData(field, value);
  };

  return (
    <div className="container">
      <div className="row">
        {/* First Name */}
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">First Name <span className="required">*</span></label>
            <input
              type="text"
              name="name"
              className="form-control"
              placeholder="Enter your first name"
              required
              value={formData.name}
              onChange={(e) => handleChange('name', e.target.value)}
            />
            {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
          </div>
        </div>

        {/* Last Name */}
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Last Name <span className="required">*</span></label>
            <input
              type="text"
              name="lastname"
              className="form-control"
              placeholder="Enter your last name"
              required
              value={formData.lastname}
              onChange={(e) => handleChange('lastname', e.target.value)}
            />
            {errors.lastname && <div className="text-danger fs-12">{errors.lastname}</div>}
          </div>
        </div>

        {/* Email */}
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Email Address <span className="required">*</span></label>
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Enter your email"
              required
              value={formData.email}
              onChange={(e) => handleChange('email', e.target.value)}
            />
            {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
          </div>
        </div>

        {/* Password */}
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Password <span className="required">*</span></label>
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="Enter your password"
              required
              value={formData.password}
              onChange={(e) => handleChange('password', e.target.value)}
            />
            {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
          </div>
        </div>

        {/* Confirm Password */}
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Confirm Password <span className="required">*</span></label>
            <input
              type="password"
              name="confirmPassword"
              className="form-control"
              placeholder="Confirm your password"
              required
              value={formData.confirmPassword}
              onChange={(e) => handleChange('confirmPassword', e.target.value)}
            />
            {errors.confirmPassword && <div className="text-danger fs-12">{errors.confirmPassword}</div>}
          </div>
        </div>

        <div className="text-center mt-4">
          <button type='button' className='btn btn-primary' onClick={validateAndNext}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
