import React from "react";

export default function DropdownSVG() {
  return (
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24" />
        <circle fill="#000000" cx="5" cy="12" r="2" />
        <circle fill="#000000" cx="12" cy="12" r="2" />
        <circle fill="#000000" cx="19" cy="12" r="2" />
      </g>
    </svg>
  );
}
