import React, { useState } from "react";

/// Scroll

import MealCard from "./components/MealCard";
import GlobalAnalysisCard from "./components/GlobalAnalysisCard";
import { Button, Card, Col, Modal } from "react-bootstrap";
import ChartPie from "./components/pie";
import WaterAndExcerciseCard from "./components/WaterAndExcerciseCard";
import ApexPie from "./components/ApexPie";
import FatChartPie from "./components/FatChartPie";
import CarbosChartPie from "./components/CarbosChartPie";

const mealTimeExamples = [
  {
    name: "almuerzo",
    time: "12:30",
    timePeriod: "pm",
  },
  {
    name: "cena",
    time: "19:00",
    timePeriod: "pm",
  },
];

const mealNames = [
  "Wake up",
  "Breakfast",
  "Morning Snack",
  "Lunch",
  "Afternoon Snack",
  "Dinner",
  "Bedtime",
];

function sortByTime(mealTimesList) {
  mealTimesList.sort((a, b) => {
    // Convertir los tiempos a números para comparar
    const timeA = parseFloat(a.time.replace(":", "."));
    const timeB = parseFloat(b.time.replace(":", "."));

    // Comparar los tiempos
    return timeA - timeB;
  });

  return mealTimesList;
}

const AnalysisTab = () => {
  const onInit = () => {
    //console.log('lightGallery has been initialized');
  };

  return (
    <div className="card-body mb-3">
      <div className="row mb-3">
        <div className="card-header border-0">
          <div className="me-auto pr-3">
            <h4 className="text-black fs-20">Análisis global</h4>
            <p className="fs-13 mb-0">Análisis global de las comidas</p>
          </div>
        </div>
        <GlobalAnalysisCard></GlobalAnalysisCard>{" "}
      </div>
      <div className="row">
        <Col xl={3} lg={3}>
          <Card>
            <Card.Header>
              <span className="text-black">
                Distribución de macronutrientes
              </span>
            </Card.Header>
            <Card.Body>
              <FatChartPie />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={3}>
          <Card>
            <Card.Header>
              <span className="text-black">Distribución de las grasas</span>
            </Card.Header>
            <Card.Body>
              <FatChartPie />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={3}>
          <Card>
            <Card.Header>
              <span className="text-black">Distribución de los hidratos</span>
            </Card.Header>
            <Card.Body>
              <CarbosChartPie />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={3}>
          <Card>
            <Card.Header>
              <span className="text-black">Distribución de los hidratos</span>
            </Card.Header>
            <Card.Body>
              <CarbosChartPie />
            </Card.Body>
          </Card>
        </Col>
      </div>

      <div className="row">
        <Col xl={3} lg={3}>
          <Card>
            <Card.Header>
              <span className="text-black">Distribución de los hidratos</span>
            </Card.Header>
            <Card.Body>
              <CarbosChartPie />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={3}>
          <Card>
            <Card.Header>
              <span className="text-black">Agua y Ejercicio físico</span>{" "}
            </Card.Header>
            <Card.Body>
              <WaterAndExcerciseCard></WaterAndExcerciseCard>
            </Card.Body>
          </Card>
        </Col>
      </div>

      <div className="row">
        
      </div>
    </div>
  );
};

export default AnalysisTab;
