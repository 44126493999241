import React from "react";

export default function PersonalhistoryCard() {
  return (
    <div className="card border rounded">
      <div className="card-header border-0">
        <div className="me-auto pr-3">
          <h4 className="text-black fs-20">Historia personal y social</h4>
          <p className="fs-13 mb-0">
          Informaciones y hábitos fisiológicos y sociales del cliente
          </p>
        </div>
      </div>
      <div className="card-body">
        <div className="basic-form">
          <form action="#">
            <div className="input-group mb-2">
              <span className="input-group-text">Funcion intestinal</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Calidad del sueno</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Fumador</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Bebe Alcohol</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Estado Civil</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Actividad fisica</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Raza</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Otras Informaciones</span>
              <textarea className="form-control"></textarea>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
