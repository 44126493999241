import React from "react";
import { Col, ListGroup, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function AnthropometricInformation() {
  return (
    <div className="card border  rounded">
      

      <div className="card-header border-0">
        <div className="me-auto pr-3">
          <h4 className="text-black fs-20">Datos Antropométricos</h4>
          <p className="fs-13 mb-0">Datos comunes del paciente</p>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="d-flex mb-1 align-items-center">
            <span className="date-icon me-3">123</span>
            <div>
              <h6 className="fs-16">
                <Link to="/workout-statistic" className="text-black">
                  Altura
                </Link>
              </h6>
              <span>centimetros</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="d-flex mb-1 align-items-center">
            <span className="date-icon me-3">80</span>
            <div>
              <h6 className="fs-16">
                <Link to="/workout-statistic" className="text-black">
                  Peso
                </Link>
              </h6>
              <span>kilogramos</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="d-flex mb-1 align-items-center">
            <span className="date-icon me-3">34</span>
            <div>
              <h6 className="fs-16">
                <Link to="/workout-statistic" className="text-black">
                  Cintura
                </Link>
              </h6>
              <span>centimetros</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="d-flex mb-1 align-items-center">
            <span className="date-icon me-3">35</span>
            <div>
              <h6 className="fs-16">
                <Link to="/workout-statistic" className="text-black">
                  Cadera
                </Link>
              </h6>
              <span>centimetros</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
