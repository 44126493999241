import React from "react";

export default function FoodHistoryCard() {
  return (
    <div className="card border  rounded">
      <div className="card-header border-0">
        <div className="me-auto pr-3">
          <h4 className="text-black fs-20">Historia alimentaria</h4>
          <p className="fs-13 mb-0">
          Hábitos y preferencias alimentarias del cliente
          </p>
        </div>
      </div>
      <div className="card-body">
        <div className="basic-form">
          <form action="#">
            <div className="input-group mb-2">
              <span className="input-group-text">Hora habitual para levantarse</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Hora habitual para acostarse</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Tipos de dieta</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Alimentos favoritos</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Alimentos rechazados</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Alergias</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Intolerancias alimentarias</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Deficiencias nutricionales</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Ingesta de agua</span>
              <textarea className="form-control"></textarea>
            </div>
            <div className="input-group mb-2">
              <span className="input-group-text">Otras informaciones</span>
              <textarea className="form-control"></textarea>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
