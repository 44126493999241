//import {format} from 'date-fns';
import { Link } from "react-router-dom";
import { ColumnFilter } from "./ColumnFilter";
import { Button } from "react-bootstrap";

function dateFormat(value) {
  let objectDate = new Date(value);
  let day = ("0" + (objectDate.getDate() + 1)).slice(-2);
  let month = ("0" + (objectDate.getMonth() + 1)).slice(-2);
  let year = objectDate.getFullYear();

  return day + "/" + month + "/" + year;
}

export const COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
    Filter: ColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Nombre",
    Footer: "Nombre",
    accessor: "first_name",
    Filter: ColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Energía",
    Footer: "Energía",
    accessor: "last_name",
    Filter: ColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Grasa",
    Footer: "Grasa",
    accessor: "email",
    Filter: ColumnFilter,
    disableFilters: true,
  },
  {
    Header: "H. Carbono",
    Footer: "H. Carbono",
    accessor: "country",
    // Cell: ({ value }) => {return dateFormat(value)},
    Filter: ColumnFilter,
    disableFilters: true,
  },
  {
    Header: "Proteina",
    Footer: "Proteina",
    accessor: "phone",
    Filter: ColumnFilter,
    disableFilters: true,
  },
//   {
//     Header: "",
//     Footer: "",
//     accessor: "age",
//     Filter: ColumnFilter,
//     disableFilters: true,
//     Cell: ({ value }) => {
//       return (
//         <td className="h-20">
//           <div className="d-flex">
//             <Button
//               as="a"
//               to="/workout-statistic"
//               className="m-2 bg-info btn-xs text-white text-center d-block rounded"
//               data-toggle="modal"
//               data-target="#addMoreTraining"
//               // onClick={() => setShowModal(true)}
//             >
//               <i className="fas fa-pen"></i>
//             </Button>
//             <Button
//               as="a"
//               to="/workout-statistic"
//               className="m-2 bg-danger btn-xs text-white text-center d-block rounded"
//               data-toggle="modal"
//               data-target="#addMoreTraining"
//               // onClick={() => setShowModal(true)}
//             >
//               <i className="fa fa-trash"></i>
//             </Button>
//           </div>
//           {/* <div className="d-flex">
//             <Link href="#" className="btn btn-primary shadow btn-xs sharp me-1">
//               <i className="fas fa-pen"></i>
//             </Link>
//             <Link href="#" className="btn btn-danger shadow btn-xs sharp">
//               <i className="fa fa-trash"></i>
//             </Link>
//           </div> */}
//         </td>
//       );
//     },
//   },
];

export const GROUPED_COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
  },
  {
    Header: "Name",
    Footer: "Name",
    columns: [
      {
        Header: "First Name",
        Footer: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        Footer: "Last Name",
        accessor: "last_name",
      },
    ],
  },
  {
    Header: "Info",
    Footer: "Info",
    columns: [
      {
        Header: "Date of  Birth",
        Footer: "Date of  Birth",
        accessor: "date_of_birth",
      },
      {
        Header: "Country",
        Footer: "Country",
        accessor: "country",
      },
      {
        Header: "Phone",
        Footer: "Phone",
        accessor: "phone",
      },
    ],
  },
];
