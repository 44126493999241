import React from "react";
import { Button } from "react-bootstrap";

export default function FoodBehaviorsCard() {
  return (
    <div className="card border rounded">
      <div className="card-header border-0">
        <div className="me-auto pr-3">
          <h4 className="text-black fs-20">Comportamientos alimentarios
</h4>
          <p className="fs-13 mb-0">
          Registra el comportamiento alimentario de tu cliente


          </p>
        </div>
        <Button
        size="sm"
          to="/diet-food-menu"
          className="btn btn-info outline rounded d-none d-lg-block ms-0 ms-md-5"
        >
          <i className="fa fa-plus " />
        </Button>
      </div>
      <div className="card-body"></div>
    </div>
  );
}
