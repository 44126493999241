import React from "react";

const StepThree = ({ formData, onFieldChange }) => {
  return (
    <section>
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Clinic/Organization Name <span className="required">*</span></label>
            <input
              type="text"
              name="clinicName"
              className="form-control"
              placeholder="Enter the name of the clinic/organization"
              required
              value={formData.clinicName}
              onChange={(e) => onFieldChange("clinicName", e.target.value)}
            />
          </div>
        </div>
        
        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Clinic Address <span className="required">*</span></label>
            <input
              type="text"
              name="clinicAddress"
              className="form-control"
              placeholder="Enter the clinic's address"
              required
              value={formData.clinicAddress}
              onChange={(e) => onFieldChange("clinicAddress", e.target.value)}
            />
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">City <span className="required">*</span></label>
            <input
              type="text"
              name="clinicCity"
              className="form-control"
              placeholder="Enter the city"
              required
              value={formData.clinicCity || ''}
              onChange={(e) => onFieldChange("clinicCity", e.target.value)}
            />
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">State/Province <span className="required">*</span></label>
            <input
              type="text"
              name="clinicState"
              className="form-control"
              placeholder="Enter the state or province"
              required
              value={formData.clinicState || ''}
              onChange={(e) => onFieldChange("clinicState", e.target.value)}
            />
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Country <span className="required">*</span></label>
            <input
              type="text"
              name="clinicCountry"
              className="form-control"
              placeholder="Enter the country"
              required
              value={formData.clinicCountry || ''}
              onChange={(e) => onFieldChange("clinicCountry", e.target.value)}
            />
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Clinic Phone Number <span className="required">*</span></label>
            <input
              type="tel"
              name="clinicPhoneNumber"
              className="form-control"
              placeholder="Enter the clinic's phone number"
              required
              value={formData.clinicPhoneNumber || ''}
              onChange={(e) => onFieldChange("clinicPhoneNumber", e.target.value)}
            />
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Clinic Email Address <span className="required">*</span></label>
            <input
              type="email"
              name="clinicEmail"
              className="form-control"
              placeholder="Enter the clinic's email address"
              required
              value={formData.clinicEmail || ''}
              onChange={(e) => onFieldChange("clinicEmail", e.target.value)}
            />
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group mb-3">
            <label className="text-label">Upload Logo/Image <span className="required">*</span></label>
            <input
              type="file"
              name="logo"
              className="form-control"
              onChange={(e) => onFieldChange("logo", e.target.files[0])}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepThree;
