export const clientsJSON = [
    {
        clientId: 101,
        clientName: "John Doe",
        occupation: "Software Engineer",
        birthDate: "1990-05-15",
        phoneNumber: "+1 555-123-4567",
        age: 34,
        country: "USA",
        email: "john.doe@example.com",
        address: "123 Main St, New York, NY",
        gender: "Male",
        idNumber: "ID1001",
        registrationDate: "2023-01-01",
        postalCode: "10001",
        status: "active"  // Added status
    },
    {
        clientId: 102,
        clientName: "Jane Smith",
        occupation: "Graphic Designer",
        birthDate: "1988-03-22",
        phoneNumber: "+44 20 7946 0958",
        age: 36,
        country: "UK",
        email: "jane.smith@example.co.uk",
        address: "45 King St, London",
        gender: "Female",
        idNumber: "ID1002",
        registrationDate: "2023-01-10",
        postalCode: "SW1A 1AA",
        status: "inactive"  // Added status
    },
    {
        clientId: 103,
        clientName: "Carlos Martinez",
        occupation: "Doctor",
        birthDate: "1975-08-30",
        phoneNumber: "+52 55 1234 5678",
        age: 49,
        country: "Mexico",
        email: "carlos.martinez@example.com",
        address: "789 Reforma Ave, Mexico City",
        gender: "Male",
        idNumber: "ID1003",
        registrationDate: "2023-02-05",
        postalCode: "01000",
        status: "active"  // Added status
    },
    {
        clientId: 104,
        clientName: "Emily Davis",
        occupation: "Teacher",
        birthDate: "1992-11-12",
        phoneNumber: "+1 555-234-5678",
        age: 31,
        country: "Canada",
        email: "emily.davis@example.ca",
        address: "678 Bloor St, Toronto, ON",
        gender: "Female",
        idNumber: "ID1004",
        registrationDate: "2023-02-15",
        postalCode: "M4W 1A8",
        status: "inactive"  // Added status
    },
    {
        clientId: 105,
        clientName: "Michael Brown",
        occupation: "Architect",
        birthDate: "1985-06-24",
        phoneNumber: "+61 2 1234 5678",
        age: 39,
        country: "Australia",
        email: "michael.brown@example.au",
        address: "12 Collins St, Sydney",
        gender: "Male",
        idNumber: "ID1005",
        registrationDate: "2023-03-03",
        postalCode: "2000",
        status: "active"  // Added status
    },
    {
        clientId: 106,
        clientName: "Sophia Lee",
        occupation: "Lawyer",
        birthDate: "1995-09-14",
        phoneNumber: "+82 2-123-4567",
        age: 29,
        country: "South Korea",
        email: "sophia.lee@example.kr",
        address: "456 Gangnam Blvd, Seoul",
        gender: "Female",
        idNumber: "ID1006",
        registrationDate: "2023-03-20",
        postalCode: "06000",
        status: "inactive"  // Added status
    },
    {
        clientId: 107,
        clientName: "Ahmed Al-Farsi",
        occupation: "Business Owner",
        birthDate: "1980-04-02",
        phoneNumber: "+971 4 123 4567",
        age: 44,
        country: "UAE",
        email: "ahmed.alfarsi@example.ae",
        address: "890 Sheikh Zayed Rd, Dubai",
        gender: "Male",
        idNumber: "ID1007",
        registrationDate: "2023-04-05",
        postalCode: "00001",
        status: "active"  // Added status
    },
    {
        clientId: 108,
        clientName: "Olivia Johnson",
        occupation: "Marketing Manager",
        birthDate: "1991-12-01",
        phoneNumber: "+1 555-345-6789",
        age: 32,
        country: "USA",
        email: "olivia.johnson@example.com",
        address: "101 Park Ave, New York, NY",
        gender: "Female",
        idNumber: "ID1008",
        registrationDate: "2023-04-15",
        postalCode: "10010",
        status: "inactive"  // Added status
    },
    {
        clientId: 109,
        clientName: "Ravi Patel",
        occupation: "Entrepreneur",
        birthDate: "1983-07-19",
        phoneNumber: "+91 22 1234 5678",
        age: 41,
        country: "India",
        email: "ravi.patel@example.in",
        address: "23 Marine Dr, Mumbai",
        gender: "Male",
        idNumber: "ID1009",
        registrationDate: "2023-05-02",
        postalCode: "400020",
        status: "active"  // Added status
    },
    {
        clientId: 110,
        clientName: "Anna Schmidt",
        occupation: "Engineer",
        birthDate: "1993-10-11",
        phoneNumber: "+49 30 1234 5678",
        age: 31,
        country: "Germany",
        email: "anna.schmidt@example.de",
        address: "56 Alexanderplatz, Berlin",
        gender: "Female",
        idNumber: "ID1010",
        registrationDate: "2023-05-22",
        postalCode: "10178",
        status: "inactive"  // Added status
    }
];

  