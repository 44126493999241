import { FC, useState, useEffect } from "react";
// import { EventModalProps } from './interfaces-modal/interface-modal';
// import "../../../assets/css/modal-style.css";
import Select from "react-select";



const clientsList = [
  {
    id: 1,
    fullName: "John Doe",
    occupation: "Software Engineer",
    clientStatus: "new",
    status: "active",
    birthDate: new Date(1990, 0, 1), // Year, Month (0-indexed), Day
    phoneNumber: "+1234567890",
    weight: 70, // Unit can be adjusted as needed (kg, lbs)
    height: 180, // Unit can be adjusted as needed (cm, in)
  },
  {
    id: 2,
    fullName: "Jane Smith",
    occupation: "Doctor",
    clientStatus: "frequent",
    status: "active",
    birthDate: new Date(1985, 5, 15),
    phoneNumber: "+9876543210",
    weight: 60,
    height: 170,
  },
  {
    id: 3,
    fullName: "Alice Jones",
    occupation: "Teacher",
    clientStatus: "old",
    status: "inactive",
    birthDate: new Date(1970, 11, 31),
    phoneNumber: "+5678901234",
    weight: 80,
    height: 165,
  },
];

const EventModal = ({
  setIsModalOpen,
  start,
  end,
  isMultiDay,
  timeStart,
  timeEnd,
  selectedEvent,
  onSubmit,
  onDelete,
  onClose,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [startTime, setStartTime] = useState(timeStart);
  const [endTime, setEndTime] = useState(timeEnd);
  const [startDate, setStartDate] = useState(start.split("T")[0]);
  const [endDate, setEndDate] = useState(end.split("T")[0]);
  const [isMultiDays, setIsMultiDay] = useState(start !== end);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    if (selectedEvent) {
      setTitle(selectedEvent.title);
      setDescription(selectedEvent?.extendedProps?.description);
      setEmail(selectedEvent?.extendedProps?.attendees[0].email);

      if (selectedEvent && selectedEvent.start && selectedEvent.end) {
        const startEventDate = new Date(selectedEvent.start);
        const endEventDate = new Date(selectedEvent.end);
        const startHourString = `${String(startEventDate.getHours()).padStart(
          2,
          "0"
        )}:${String(startEventDate.getMinutes()).padStart(2, "0")}`;
        setStartTime(startHourString);
        const eventIsMultiDay =
          startEventDate.toISOString().split("T")[0] !==
          endEventDate.toISOString().split("T")[0];
        setIsMultiDay(eventIsMultiDay);
        const endHourString = `${String(endEventDate.getHours()).padStart(
          2,
          "0"
        )}:${String(endEventDate.getMinutes()).padStart(2, "0")}`;
        setEndTime(endHourString);
        setStartDate(startEventDate.toISOString().split("T")[0]);
        if (eventIsMultiDay) {
          endEventDate.setDate(endEventDate.getDate());
          setEndDate(endEventDate.toISOString().split("T")[0]);
        } else {
          setEndDate(startEventDate.toISOString().split("T")[0]);
        }
      } else {
        setStartDate(start.split("T")[0]);
        setEndDate(end.split("T")[0]);
        setStartTime(timeStart);
        setEndTime(timeEnd);
      }
    }
  }, [selectedEvent, start, end, timeStart, timeEnd]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSubmit = () => {
    if (!description || !startTime || !endTime) {
      alert("Por favor, complete todos los campos.");
      return;
    }

    const startDateTime = new Date(`${startDate}T${startTime}`);
    const endDateTime = isMultiDays
      ? new Date(`${endDate}T${endTime}`)
      : new Date(`${startDate}T${endTime}`);

    if (startDateTime >= endDateTime) {
      alert("La hora de fin debe ser posterior a la hora de inicio.");
      return;
    }

    const id = selectedEvent ? selectedEvent.id : "";
    const color = selectedEvent ? selectedEvent.color : "green";
    const location = selectedEvent ? selectedEvent.extendedProps?.location : "";

    onSubmit({
      id: id,
      title: title,
      start: startDateTime,
      end: endDateTime,
      color: color,
      extendedProps: {
        location: location,
        description,
        attendees: [{ email }],
      },
    });
    onClose();
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="mb-4">
          <p>Selecciona el cliente</p>
        </div>
        <Select
          // defaultValue={selectedOption}
          onChange={(e) => {
            setSelectedClient(clientsList.find((client) => client.id == e.option));
          }}
          options={clientsList.map((client, id) => ({
            option: client.id,
            label: client.fullName,
          }))}
          style={{
            lineHeight: "40px",
            color: "#7e7e7e",
            paddingLeft: " 15px",
          }}
        />

        <div className="col-xl-12  col-lg-12 col-sm-12 mt-3">
          {selectedClient ? (
            <div className="widget-stat card bg-light">
              <div className="card-body p-4">
                <div className="media">
                  <span className="me-3">
                    <i className="la la-user"></i>
                  </span>
                  <div className="media-body text-gray">
                    <p className="mb-1 text-green">Cliente nuevo</p>
                    <h3 className="text-gray">{selectedClient.fullName}</h3>
                    <small>{selectedClient.occupation}</small>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="pt-1">
        <div className="settings-form">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row ">
              <div className="card-header">
                <h4 className="card-title">Horario</h4>
              </div>

              {/* Start Date */}
              <div className="form-group mb-3 col-md-3 color-time-picker">
                <p className="mb-1">Inicio de la consulta</p>
                <input
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  placeholder=""
                  className="form-control"
                />
              </div>

              {/* Start Time */}
              <div className="form-group mb-3 col-md-3 color-time-picker">
                <p className="mb-1">Hora Inicio</p>
                <input
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  placeholder=""
                  className="form-control"
                />
              </div>

              {/* End Date */}
              <div className="form-group mb-3 col-md-3 color-time-picker">
                <p className="mb-1">Fin de la consulta</p>
                <input
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  placeholder=""
                  className="form-control"
                />
              </div>

              {/* Start Time */}
              <div className="form-group mb-3 col-md-3 color-time-picker">
                <p className="mb-1">Hora Fin</p>
                <input
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  placeholder=""
                  className="form-control"
                />
              </div>
            </div>

            {/* Details */}

            <div className="row ">
              <div className="card-header">
                <h4 className="card-title">Detalles</h4>
              </div>

              {/* status */}
              <div className="form-group mb-3 col-md-3">
                <label className="form-label">Estado</label>
                <select
                  className="form-control"
                  id="inputState"
                  defaultValue="option-1"
                >
                  <option disabled value="option-1">
                    Elige...
                  </option>
                  <option value="option-2">Confirmada</option>
                  <option value="option-3">No Confirmada</option>
                </select>
              </div>

              {/* Place */}
              <div className="form-group mb-3 col-md-3">
                <label className="form-label">Lugar</label>
                <select
                  className="form-control"
                  id="inputState"
                  defaultValue="option-1"
                >
                  <option disabled value="option-1">
                    Elige...
                  </option>
                  <option value="option-2">Consultorio 1</option>
                  <option value="option-3">Consultorio 2</option>
                </select>
              </div>
            </div>

            <div className="row ">
              <div className="card-header">
                <h4 className="card-title">Videoconferencia</h4>
              </div>

              {/* status */}
              <div className="form-group mb-3 col-md-4">
                <label className="form-label">Herramienta</label>
                <select
                  className="form-control"
                  id="inputState"
                  defaultValue="option-1"
                >
                  <option disabled value="option-1">
                    Elige...
                  </option>
                  <option value="option-2">Google Meet</option>
                  <option value="option-3">Zoom</option>
                  <option value="option-3">Otra</option>
                </select>
              </div>

              {/* Place */}
              <div className="form-group mb-3 col-md-8">
                <label className="form-label">
                  Enlace de la videoconferencia
                </label>
                <input
                  type="text"
                  placeholder="Apartment, studio, or floor"
                  className="form-control"
                />
              </div>
            </div>

            {/* Notes */}

            <div className="row ">
              <div className="card-header">
                <h4 className="card-title">Notas</h4>
              </div>
              {/* status */}
              <div className="form-group mb-12 col-md-12">
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Apartment, studio, or floor"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group mb-3">
              <div className="form-check custom-checkbox">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="gridCheck"
                />
                <label className="form-check-label" htmlFor="gridCheck">
                  Enviar correo a cliente
                </label>
              </div>
            </div>
            <button
              onClick={handleSubmit}
              className="btn btn-primary btn-sm "
              // type="submit"
            >
              {selectedEvent ? "Actualizar evento" : "Agregar evento"}
            </button>
            <button onClick={onClose} className="btn btn-danger btn-sm">Cancelar</button>
          </form>
        </div>
      </div>
      {/* <div>
        <div>
          <label>
            Título
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Descripción
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Email
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Hora de inicio
            <input
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Hora de fin
            <input
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Fecha de inicio
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </label>
        </div>
        {isMultiDays && (
          <div>
            <label>
              Fecha de fin
              <input
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </label>
          </div>
        )}
        <button onClick={handleSubmit}>
          {selectedEvent ? "Actualizar evento" : "Agregar evento"}
        </button>
        {selectedEvent && (
          <button onClick={onDelete} style={{ marginLeft: "10px" }}>
            Eliminar
          </button>
        )}
        <button onClick={onClose} style={{ marginLeft: "10px" }}>
          Cerrar
        </button>
      </div> */}
    </div>
  );
};

export default EventModal;
