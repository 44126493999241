import React, { useEffect, useReducer, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";

/// Scroll

import { Link } from "react-router-dom";
import { calculateNutritionalSummary } from "./utils";
import DropdownSVG from "../../DropdownSVG";

let foodListExample = [
  {
    id: 1,
    name: "Manzana",
    unit: "unidad",
    quantity: 1,
    weight: 150, // en gramos
    weightUnit: "g",
    energy: 52, // en kcal
    carbos: 14, // en gramos
    fat: 0.2, // en gramos
    protein: 0.3, // en gramos
  },
  {
    id: 2,
    name: "Agua mineral",
    unit: "botella",
    quantity: 1,
    weight: 500, // en mililitros
    weightUnit: "ml",
    energy: 0, // en kcal
    carbos: 0, // en gramos
    fat: 0, // en gramos
    protein: 0, // en gramos
  },
  {
    id: 3,
    name: "Pollo",
    unit: "filete",
    quantity: 1,
    weight: 113, // en gramos
    weightUnit: "g",
    energy: 165, // en kcal
    carbos: 0, // en gramos
    fat: 3.6, // en gramos
    protein: 31, // en gramos
  },
  {
    id: 4,
    name: "Café",
    unit: "taza",
    quantity: 1,
    weight: 240, // en mililitros
    weightUnit: "ml",
    energy: 2, // en kcal
    carbos: 0, // en gramos
    fat: 0, // en gramos
    protein: 0.3, // en gramos
  },
  {
    id: 5,
    name: "Leche",
    unit: "vaso",
    quantity: 1,
    weight: 244, // en gramos (240 ml)
    weightUnit: "g",
    energy: 122, // en kcal
    carbos: 11.7, // en gramos
    fat: 4.8, // en gramos
    protein: 8, // en gramos
  },
  {
    id: 6,
    name: "Cerveza",
    unit: "lata",
    quantity: 1,
    weight: 355, // en mililitros
    weightUnit: "ml",
    energy: 153, // en kcal
    carbos: 12.6, // en gramos
    fat: 0, // en gramos
    protein: 1.6, // en gramos
  },
  {
    id: 7,
    name: "Atún en lata",
    unit: "lata",
    quantity: 1,
    weight: 100, // en gramos
    weightUnit: "g",
    energy: 91, // en kcal
    carbos: 0, // en gramos
    fat: 1, // en gramos
    protein: 20, // en gramos
  },
  {
    id: 8,
    name: "Jugo de naranja",
    unit: "vaso",
    quantity: 1,
    weight: 250, // en mililitros
    weightUnit: "ml",
    energy: 112, // en kcal
    carbos: 26, // en gramos
    fat: 0.5, // en gramos
    protein: 1.7, // en gramos
  },
  {
    id: 9,
    name: "Vino tinto",
    unit: "copa",
    quantity: 1,
    weight: 150, // en mililitros
    weightUnit: "ml",
    energy: 123, // en kcal
    carbos: 3.8, // en gramos
    fat: 0, // en gramos
    protein: 0.1, // en gramos
  },
  {
    id: 10,
    name: "Gaseosa",
    unit: "lata",
    quantity: 1,
    weight: 355, // en mililitros
    weightUnit: "ml",
    energy: 139, // en kcal
    carbos: 39.4, // en gramos
    fat: 0, // en gramos
    protein: 0, // en gramos
  },
];

const MealCard = ({ meal, setEditedMealTime, setActiveEditModal, onDeleteMealTime }) => {
  const onInit = () => {
    //console.log('lightGallery has been initialized');
  };

  const [activeModal, setActiveModal] = useState(false);

  const [foodsList, setFoodsList] = useState(foodListExample);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editedProduct, setEditedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [nutritionalSummary, setNutritionalSummary] = useState(null);

  const [mealFoodsList, setMealFoodsList] = useState([]);

  function onDeleteFood(id) {
    let newMealFoodsList = mealFoodsList.filter((p) => p.id != id);

    setMealFoodsList(newMealFoodsList);

    var nutriSummary = calculateNutritionalSummary(newMealFoodsList);
    setNutritionalSummary(nutriSummary);
  }

  function onEditFood() {
    // Actualizar las propiedades del alimento en la lista
    const index = mealFoodsList.findIndex((p) => p.name == editedProduct.name);

    const newFood = {
      ...editedProduct,
      quantity: quantity,
    };

    let newMealFoodsList = mealFoodsList;
    newMealFoodsList[index] = { ...mealFoodsList[index], ...newFood };

    setMealFoodsList(newMealFoodsList);

    var nutriSummary = calculateNutritionalSummary(newMealFoodsList);
    setNutritionalSummary(nutriSummary);
    setActiveModal(false);
    setEditedProduct(null);
  }

  function onAddFood() {
    const newFood = {
      ...selectedProduct,
      quantity: quantity,
    };

    let newMealFoodsList = mealFoodsList;

    newMealFoodsList.push(newFood);
    setMealFoodsList(newMealFoodsList);

    var nutriSummary = calculateNutritionalSummary(newMealFoodsList);
    setNutritionalSummary(nutriSummary);
    setActiveModal(false);
    setSelectedProduct(null);
    setQuantity(1);
  }



  function onChangeSelect(e) {
    const selectedProd = foodsList[e.target.value];
    setSelectedProduct(selectedProd);
  }


  return (
    <div className="card border  rounded">
      <div className="card-header border-0">
        <h2 className="fs-26 text-black font-w600 mb-0">
          {meal?.name?.toUpperCase()}
          <small className="text-muted ml-2 fs-16">
            {"    "}
            <i className="las la-clock scale5 me-1" />
            {"" + meal.time}
          </small>
        </h2>
        {/* <h4 className="text-black fs-20">
          {meal.name.toUpperCase()}
          <p className="price float-left d-block">{meal.time}</p>
        </h4> */}

        <Dropdown className="dropdown mb-1">
          <Dropdown.Toggle
            variant=" light"
            className="btn-info i-false p-0 sharp"
          >
            <DropdownSVG></DropdownSVG>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu">
            <Dropdown.Item
              className="dropdown-item"
              to="/widget-basic"
              onClick={(e) => {
                setEditedMealTime(meal);
                setActiveEditModal(true);
              }}
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              className="dropdown-item"
              to="/widget-basic"
              onClick={(e) => {
                onDeleteMealTime(meal.name);
              }}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="card-body">
        <div className="row mb-4">
          <div className="col-xl-12 col-lg-12 ">
            <div
              id="DZ_W_Todo4"
              // className="widget-media dz-scroll height120 border border-2 border-primary"
              className="widget-media "
            >
              <ul className="timeline">
                {mealFoodsList.map((food, index) => (
                  <li key={index}>
                    <div className="timeline-badge dark"></div>
                    <div className="timeline-panel border-bottom border-top border-info">
                      <div className="media-body">
                        <h5 className="mb-0">
                          {food.quantity + "  "}
                          {"(" + food.unit + ")  "}
                          {food.name}
                          <small className="text-muted ml-2">
                            {"   /" +
                              food.weight * food.quantity +
                              " " +
                              food.weightUnit}
                          </small>
                        </h5>
                      </div>
                      <Dropdown className="dropdown mb-1">
                        <Dropdown.Toggle
                          variant=" light"
                          className="btn-info i-false p-0 sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                            onClick={(e) => {
                              setEditedProduct(mealFoodsList[index]);
                              setActiveModal(true);
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                            onClick={(e) => {
                              onDeleteFood(food.id);
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 ">
            <div className="row mb-3">
              <div className="col-lg-3 col-sm-3 col-3">
                <h2 className="fs-24 text-black font-w600 mb-0">
                  {!nutritionalSummary ? 0 : nutritionalSummary.totalEnergy}
                  <small className="text-muted ml-2 fs-16">{" /kcal"}</small>
                </h2>
                <span className="fs-14">Energía</span>
              </div>
              <div className="col-lg-3 col-sm-3 col-3">
                <h2 className="fs-24 text-black font-w600 mb-0">
                  {!nutritionalSummary ? 0 : nutritionalSummary.totalFat}
                  <small className="text-muted ml-2 fs-16">{" /g"}</small>
                </h2>
                <span className="fs-14">Grasa</span>
              </div>
              <div className="col-lg-3 col-sm-3 col-3">
                <h2 className="fs-24 text-black font-w600 mb-0">
                  {!nutritionalSummary ? 0 : nutritionalSummary.totalCarbos}
                  <small className="text-muted ml-2 fs-16">{" /g"}</small>
                </h2>
                <span className="fs-14">H. Carbono</span>
              </div>
              <div className="col-lg-3 col-sm-3 col-3">
                <h2 className="fs-24 text-black font-w600 mb-0">
                  {!nutritionalSummary ? 0 : nutritionalSummary.totalProtein}
                  <small className="text-muted ml-2 fs-16">{" /g"}</small>
                </h2>
                <span className="fs-14">Proteína</span>
              </div>
            </div>
            {/* <button className="btn btn-primary me-2">
												<span className="me-2"> <i className="fa fa-heart" /> </span>Like 
											</button> */}
            <button
              onClick={() => setActiveModal(true)}
              className="btn btn-secondary btn-xs"
            >
              <span className="me-1">
                {" "}
                <i className="fa fa-plus" />
              </span>
              Agregar alimento
            </button>
          </div>
        </div>
      </div>

      {/* Modal to add a food */}

      <Modal
        size="lg"
        show={activeModal}
        className="fade"
        id="addMoreTraining"
        onHide={() => {
          setActiveModal(false);
          setSelectedProduct(null);
          setQuantity(1);
        }}
      >
        <div className="modal-content">
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title">
              Agregar/Editar Alimento
            </Modal.Title>
            <Button
              variant=""
              onClick={() => {
                setSelectedProduct(null);
                setQuantity(1);
                setActiveModal(false);
                setQuantity(1);
              }}
              type="button"
              className="close"
            >
              <span>×</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group">
                <label for="frutas">Seleccione una comida:</label>
                <div class="input-group">
                  <select
                    onChange={(e) => onChangeSelect(e)}
                    className="form-control"
                    id="inputGroupSelect04"
                  >
                    <option selected>
                      {editedProduct ? editedProduct.name : "Choose..."}
                    </option>
                    {foodsList.map((food, index) => (
                      <option value={index}>{food.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-group">
                <label>Cantidad</label>
                <input
                  onChange={(e) => setQuantity(e.target.value)}
                  type="number"
                  className="form-control"
                  placeholder="1"
                  defaultValue={editedProduct ? editedProduct.quantity : 1}
                />
              </div>
              <div className="form-group">
                <label>Unidad</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  defaultValue={
                    editedProduct ? editedProduct.unit : selectedProduct?.unit
                  }
                />
              </div>
              <div className="form-group">
                <label>Equivalencia</label>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  value={
                    editedProduct
                      ? `${editedProduct.weight * editedProduct.quantity} ${
                          editedProduct.weightUnit
                        }`
                      : selectedProduct
                      ? `${selectedProduct.weight * quantity} ${
                          selectedProduct.weightUnit
                        }`
                      : ""
                  }
                />
              </div>
              {editedProduct ? (
                <button
                  onClick={(e) => onEditFood()}
                  className="btn btn-primary"
                >
                  Guardar
                </button>
              ) : (
                <button
                  onClick={(e) => onAddFood()}
                  className="btn btn-primary"
                >
                  Agregar
                </button>
              )}
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default MealCard;
