import React from "react";

export default function WaterIcon() {
  return (
    <div className="d-inline-block me-3 position-relative donut-chart-sale2">
      <svg className="peity" height={70} width={70}>
        <path
          d="M 35 0 A 35 35 0 1 1 0 35.00000000000001 L 8 35 A 27 27 0 1 0 35 8"
          data-value={6}
          fill="rgb(30, 167, 197)"
        />
        <path
          d="M 0 35.00000000000001 A 35 35 0 0 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 0 0 8 35"
          data-value={2}
          fill="rgba(255, 255, 255, 1)"
        />
      </svg>

      <small className="text-info">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11 5H9V21H11V5ZM13 5H15V21H13V5ZM15 3H13V1H11V3H9C7.34315 3 6 4.34315 6 6V22H18V6C18 4.34315 16.6569 3 15 3ZM13 19H11V17H13V19ZM15 19H15.01H17V17H15V19ZM9 17H11V19H9V17ZM9 15H11V13H9V15ZM9 11H11V9H9V11Z"
            fill="#1EA7C5"
          />
        </svg>
      </small>
    </div>
  );
}
