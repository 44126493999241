import React from "react";
import { Link } from "react-router-dom";

export default function AnalyticsDataCard() {
  return (
    <div className="card border rounded">
      <div className="card-header border-0">
        <div className="me-auto pr-3">
          <h4 className="text-black fs-20">Datos Analiticos</h4>
          <p className="fs-13 mb-0">
            Datos analiticos del paciente
          </p>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="d-flex mb-1 align-items-center">
            <span className="date-icon me-3">123</span>
            <div>
              <h6 className="fs-16">
                <Link to="/workout-statistic" className="text-black">
                  Colesterol HDL
                </Link>
              </h6>
              <span>centimetros</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="d-flex mb-1 align-items-center">
            <span className="date-icon me-3">80</span>
            <div>
              <h6 className="fs-16">
                <Link to="/workout-statistic" className="text-black">
                Colesterol LDL
                </Link>
              </h6>
              <span>kilogramos</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="d-flex mb-1 align-items-center">
            <span className="date-icon me-3">34</span>
            <div>
              <h6 className="fs-16">
                <Link to="/workout-statistic" className="text-black">
                Colesterol Total
                </Link>
              </h6>
              <span>centimetros</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="d-flex mb-1 align-items-center">
            <span className="date-icon me-3">35</span>
            <div>
              <h6 className="fs-16">
                <Link to="/workout-statistic" className="text-black">
                  Presion arterial diastolica
                </Link>
              </h6>
              <span>centimetros</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="d-flex mb-1 align-items-center">
            <span className="date-icon me-3">35</span>
            <div>
              <h6 className="fs-16">
                <Link to="/workout-statistic" className="text-black">
                  Presion arterial Sistolica
                </Link>
              </h6>
              <span>centimetros</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="d-flex mb-1 align-items-center">
            <span className="date-icon me-3">35</span>
            <div>
              <h6 className="fs-16">
                <Link to="/workout-statistic" className="text-black">
                  Trigliceridos
                </Link>
              </h6>
              <span>centimetros</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
