import React from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function GlobalAnalysisCard() {
  return (
    <div className="card border  rounded">
      <div className="card-header border-0">
        <div className="me-auto pr-3">
          <h4 className="text-black fs-20">Análisis global</h4>
          <p className="fs-13 mb-0">Análisis global de las comidas</p>
        </div>
      </div>
      <div className="card-body">
        <div className="row ">
          <div className="media mb-2 mt-3">
            <div className="media-body">
              <span className="pull-right  text-gray">/1910</span>
              <span className="pull-right text-black">1968</span>
              <h5 className="my-1 text-black">Energía</h5>
              <p className="read-content-email">
                <ProgressBar now={60} variant="danger" />
              </p>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="media mb-2 mt-3">
            <div className="media-body">
              <span className="pull-right  text-gray">/1910</span>
              <span className="pull-right text-black">1968</span>
              <h5 className="my-1 text-black">Grasa</h5>
              <p className="read-content-email">
                <ProgressBar now={60} variant="secondary" />
              </p>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="media mb-2 mt-3">
            <div className="media-body">
              <span className="pull-right  text-gray">/1910</span>
              <span className="pull-right text-black">1968</span>
              <h5 className="my-1 text-black">Hidratos de Carbono</h5>
              <p className="read-content-email">
                <ProgressBar now={60} variant="info" />
              </p>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="media mb-0 mt-3">
            <div className="media-body">
              <span className="pull-right  text-gray">/1910</span>
              <span className="pull-right text-black">1968</span>
              <h5 className="my-1 text-black">Proteína</h5>
              <p className="read-content-email">
                <ProgressBar now={60} variant="green" />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="card-header border-0">
        <div className="me-auto pr-3">
          <h4 className="text-black fs-20">Análisis global</h4>
          <p className="fs-13 mb-0">Análisis global de las comidas</p>
        </div>
      </div>
    </div>
  );
}
