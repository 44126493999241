import React from "react";
import { Dropdown } from "react-bootstrap";
import ApexLine from "./Line";


export default function LastMeasurementsCard() {
  return (
    <div className="card border rounded shadow-warning">
      <div className="card-header border-0">
        <h4 className="text-black fs-20">Ultimas mediciones</h4>
      </div>
        <div className="col-xl-12">
            <div className="card-body pb-0">
              <ApexLine />
          </div>
        </div>
    </div>
  );
}
