import axios from 'axios';
import swal from "sweetalert";
import { loginConfirmedAction, Logout, } from '../store/actions/AuthActions';

export function signUp(email, password, name, lastname) {
    //axios call
    const postData = {
        name_rol: "ADMIN",
        name,
        last_name: lastname,
        email,
        password,
        fk_rol_id: 1,
    };
    return axios.post(
        `http://127.0.0.1:8000/users/`,
        postData,
    );
}

export function confirmEmail(email) {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const REGISTER_URL = process.env.REACT_APP_REGISTER_URL;

    const postData = {
        email,
        url: `${BASE_URL}${REGISTER_URL}`,
    };

    return axios.post(
        `http://127.0.0.1:8001/submit/`,
        postData,
    );
}

export function validEmail(email) {
    return axios.get(`http://127.0.0.1:8000/users/exists/${email}/`);
}

export function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    // return axios.post(
    //     `http://127.0.0.1:8000/users/login/`,
    //     postData,
    // );


    return {
        "id": 1,
        "name": "John",
        "last_name": "Doe",
        "email": "hash_of_email@example.com",
        "phone": "hash_of_phone_number",
        "password": "hash_of_password",
        "registration_date": "2023-12-06",
        "provincia": "Example Province",
        "canton": "Example Canton",
        "address": "123 Main Street",
        "fk_rol_id": 2,
        "dob": "1990-01-01"
      }
}

export function formatError(errorResponse) {
    switch (errorResponse) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case "Incorrect email or password":
            swal("Oops", "Incorrect email or password", "error", { button: "Try Again!", });
            break;
        case 'User not found':
            swal("Oops", "User not found", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';
        default:
            return 'Something went wrong';
    }
}


export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expires_in * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
		return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }
		
    dispatch(loginConfirmedAction(tokenDetails));
	
    const timer = (expireDate.getTime() - todaysDate.getTime());
    runLogoutTimer(dispatch, timer, navigate);
    
}
