import React from 'react';

export const GlobalFilter = ( {filter, setFilter} ) =>{
	return(
		<div>
			{/* Search : {' '} */}
			<input className="ms-2 input-search form-control border-primary"
				value={filter || ''}  onChange={e => setFilter(e.target.value)} style={{width: "20%"}}
            />
		</div>
	)
} 