import React, { Fragment, useState } from "react";
import { Stepper, Step } from 'react-form-stepper';

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import PageTitle from "../../../layouts/PageTitle";

const Wizard = () => {
	const [goSteps, setGoSteps] = useState(0);
	const [formData, setFormData] = useState({
		name: '',
		lastname: '',
		email: '',
		password: '',
		confirmPassword: '',
		lincenseNumber: '',
		clinicName: '',
		clinicAddress: '',
		clinicCity: '',
		clinicState: '',
		clinicCountry: '',
		clinicPhoneNumber: '',
		clinicEmail: '',
		workDays: {
			Monday: false,
			Tuesday: false,
			Wednesday: false,
			Thursday: false,
			Friday: false,
			Saturday: false,
			Sunday: false
		},
		startTime: '08:00',
		endTime: '15:00',
	});

	const handleFieldChange = (field, value) => {
		setFormData(prevFormData => ({
			...prevFormData,
			[field]: value,
		}));
	};

	const handleNextStep = () => {
		setGoSteps(prevActiveStep => prevActiveStep + 1);
	};

	const handlePrevStep = () => {
		setGoSteps(prevActiveStep => prevActiveStep - 1);
	};

	return (
		<Fragment>
			<PageTitle activeMenu="Wizard" motherMenu="Form" />
			<div className="row">
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-header">
							<h4 className="card-title">Register</h4>
						</div>
						<div className="card-body">
							<div className="form-wizard ">
								<Stepper className="nav-wizard" activeStep={goSteps}>
									<Step className="nav-link" onClick={() => setGoSteps(0)} />
									<Step className="nav-link" onClick={() => setGoSteps(1)} />
									<Step className="nav-link" onClick={() => setGoSteps(2)} />
									<Step className="nav-link" onClick={() => setGoSteps(3)} />
								</Stepper>
								{goSteps === 0 && (
									<>
										<StepOne
											formData={formData}
											setFormData={handleFieldChange}
											onStepChange={handleNextStep}
										/>
									</>
								)}
								{goSteps === 1 && (
									<>
										<StepTwo
											formData={formData}
											onFieldChange={handleFieldChange}
										/>
										<div className="text-end toolbar toolbar-bottom p-2">
											<button className="btn btn-secondary sw-btn-prev me-1" onClick={handlePrevStep}>Prev</button>
											<button className="btn btn-primary sw-btn-next ms-1" onClick={handleNextStep}>Next</button>
										</div>
									</>
								)}
								{goSteps === 2 && (
									<>
										<StepThree
											formData={formData}
											onFieldChange={handleFieldChange}
										/>
										<div className="text-end toolbar toolbar-bottom p-2">
											<button className="btn btn-secondary sw-btn-prev me-1" onClick={handlePrevStep}>Prev</button>
											<button className="btn btn-primary sw-btn-next ms-1" onClick={handleNextStep}>Next</button>
										</div>
									</>
								)}
								{goSteps === 3 && (
									<>
										<StepFour
											formData={formData}
											setFormData={handleFieldChange}
										/>
										<div className="text-end toolbar toolbar-bottom p-2">
											<button className="btn btn-secondary sw-btn-prev me-1" onClick={handlePrevStep}>Prev</button>
											<button className="btn btn-primary sw-btn-next ms-1" onClick={() => { /* Aquí manejarías el envío del formulario completo */ }}>Submit</button>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Wizard;