import React, { useState } from "react";
import { Button, Modal, Dropdown } from "react-bootstrap";
import DropdownSVG from "../../DropdownSVG";
import { v4 as uuidv4 } from 'uuid';

export default function FilesCard() {
  const [activeModal, setActiveModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [filesList, setFilesList] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDelete = (fileId) => {

    let newFilesList=filesList.filter(file=>file.fileId!=fileId)
    setFilesList(newFilesList);
    

    setActiveModal(false)
    setSelectedFile(null)
  };

  const handleSubmit = async (event) => {
    if (!selectedFile) {
      console.log("No file selected!");
      return;
    }
    const fileId = uuidv4();
    const newFile={file:selectedFile,fileId,state:"uploading"}

    setFilesList([...filesList, newFile]);
    

    // const formData = new FormData();
    // formData.append("file", selectedFile);

    // try {
    //   const response = await fetch("/upload", {
    //     method: "POST",
    //     body: formData,
    //   });

    //   // Handle response from the server
    //   console.log("File uploaded successfully!", response);
    // } catch (error) {
    //   console.error("Error uploading file:", error);
    // }

    setActiveModal(false)
    setSelectedFile(null)
  };

  return (
    <div className="card border rounded">
      <div className="card-header border-0">
        <div className="me-auto pr-3">
          <h4 className="text-black fs-20">Archivos</h4>
          <p className="fs-13 mb-0">Archivos adjuntos del cliente</p>
        </div>
        <Button
          onClick={() => setActiveModal(true)}
          size="sm"
          to="/diet-food-menu"
          className="btn btn-info outline rounded d-none d-lg-block ms-0 ms-md-5"
        >
          <i className="fa fa-plus " />
        </Button>
      </div>
      <div className="card-body">
        <table className="table table-sm mb-0 text-black">
          <tbody id="orders">
            {filesList.map((file, index) => (
              <tr key={index} className="btn-reveal-trigger">
                <td className="py-2">{file.file.name}</td>
                <td className="py-2 text-end">
                  <span className="badge badge-primary badge-sm light">
                    {file.state?.toUpperCase()}
                    <span className="ms-1 fa fa-up" />
                  </span>
                </td>
                <td className="py-2 text-end">
                  <Dropdown className="dropdown text-sans-serif">
                    <Dropdown.Toggle
                      variant=""
                      className="btn btn-primary tp-btn-light sharp icon-false"
                      type="button"
                      id="order-dropdown-0"
                      data-toggle="dropdown"
                      data-boundary="viewport"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span>
                        <DropdownSVG></DropdownSVG>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="dropdown-menu dropdown-menu-right border py-0"
                      aria-labelledby="order-dropdown-0"
                    >
                      <div className="py-2">
                        <a onClick={()=> handleDelete(file.fileId)} className="dropdown-item" href="#!">
                          Eliminar
                        </a>
                        <a target="_blank" className="dropdown-item" href={URL.createObjectURL(file.file)}>
                          Ver
                        </a>
                        <a className="dropdown-item" href="#!">
                          Descargar
                        </a>
                        {/*  <a className="dropdown-item" href="#!">
                    On Hold
                  </a>
                  <a className="dropdown-item" href="#!">
                    Pending
                  </a>
                  <div className="dropdown-divider" />
                  <a className="dropdown-item text-danger" href="#!">
                    Delete
                  </a> */}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal to add a file */}

      <Modal
        size="lg"
        show={activeModal}
        className="fade"
        id="addMoreTraining"
        onHide={() => {
          setActiveModal(false);
        }}
      >
        <div className="modal-content">
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title">
              Agregar/Editar Archivo a la consulta
            </Modal.Title>
            <Button
              variant=""
              onClick={() => {
                setActiveModal(false);
              }}
              type="button"
              className="close"
            >
              <span>×</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <input
                onChange={handleFileChange}
                className="form-control"
                type="file"
                id="formFile"
              />
            </div>

            <button className="btn btn-primary" onClick={() => handleSubmit()}>
              Agregar
            </button>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
}
