import React from "react";

import PageTitle from "../../layouts/PageTitle";
import EventCalendar from "./components/EventCalendar";
import Calendar from "./Calendarcomponent/components/calendar/calendar";
const CalendarDashboard = () => {
   return (
      <div className="h-80 border-2 border-groove">
         {/* <PageTitle activeMenu="Calerdar" motherMenu="App" /> */}

            <Calendar></Calendar>
            
      </div>
   );
};

export default CalendarDashboard;
