import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";

const costaRicanProvinces = [
  "San José",
  "Alajuela",
  "Cartago",
  "Heredia",
  "Guanacaste",
  "Puntarenas",
  "Limón",
];

const costaRicanCantons = {
  "San José": [
    {
      name: "San José",
      districts: [
        "Carmen",
        "Catedral",
        "Hospital",
        "Mata Redonda",
        "Merced",
        // Add more districts for San José
      ],
    },
    {
      name: "Escazú",
      districts: [
        "Escazú",
        "San Antonio",
        "San Rafael",
        // Add more districts for Escazú
      ],
    },
    // Add more cantons for San José
  ],
  "Alajuela": [
    {
      name: "Alajuela",
      districts: [
        "Alajuela",
        "San Ramón",
        "Grecia",
        "San Mateo",
        "Atenas",
        // Add more districts for Alajuela
      ],
    },
    // Add more cantons for Alajuela
  ],
  // Add more provinces
};

const AddClientModal = ({ addClient,setShowModal, showModal }) => {
  const [imgFile, setImgFile] = useState(null);

  const [client, setClient] = useState(null);
  const [province, setProvince] = useState("");
  const [canton, setCanton] = useState("")

  function onHandleAddClient() {
    addClient({...client,profileImg:imgFile})
  }

  return (
    <Modal
      className="modal-lg fade"
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header>
        <Modal.Title>
          <h4 className="text-primary">Registrar un nuevo cliente</h4>
        </Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => setShowModal(false)}
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-4 m-auto">
            <div className="img-bx image-placeholder">
              <div className="avatar-edit">
                <input
                  type="file"
                  onChange={(e) => setImgFile(e.target.files[0])}
                  id="imageUpload"
                  accept="image/png, image/jpeg, image/jpg"
                  // onClick={(event) => setFile(event.target.value)}
                />
                <label htmlFor="imageUpload" name=""></label>
              </div>
              <img
                src={
                  imgFile
                    ? URL.createObjectURL(imgFile)
                    : "https://cdn-icons-png.flaticon.com/512/3135/3135768.png"
                }
                alt=""
                className="me-3 card-list-img w-100 rounded"
                width="130"
              />
            </div>{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="settings-form">
              <form onSubmit={(e) => e.preventDefault()}>
                {/* ==================================================== */}
                <div className="row">
                  <div className="form-group mb-3 col-md-12">
                    <label className="text-info form-label font-weight-normal">
                      Nombre Completo
                    </label>
                    <input
                      type="text"
                      placeholder="Nombre Completo"
                      className="form-control"
                      onChange={(e) =>
                        setClient({ ...client, fullName: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group mb-3 col-md-3">
                    <label className="form-label">Nacimiento</label>
                    <input
                      type="date"
                      placeholder="fecha nacimiento"
                      className="form-control"
                      onChange={(e) =>
                        setClient({ ...client, birthday: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group mb-3 col-md-3">
                    <label className="form-label">Genero</label>
                    <select
                      className="form-control"
                      id="inputState"
                      defaultValue="option-1"
                      onChange={(e) =>
                        setClient({ ...client, gender: e.target.value })
                      }
                    >
                      <option disabled>Escoger...</option>
                      <option value="male">Masculino</option>
                      <option value="female">Femenino</option>
                    </select>
                  </div>

                  <div className="form-group mb-3 col-md-3">
                    <label className="form-label">Lugar de Consulta</label>
                    <select
                      className="form-control"
                      id="inputState"
                      defaultValue="option-1"
                      onChange={(e) =>
                        setClient({
                          ...client,
                          appointmentPlace: e.target.value,
                        })
                      }
                    >
                      <option disabled value="option-1">
                        Escoger...
                      </option>
                      <option value="option-2">Place 1</option>
                      <option value="option-3">Place 2</option>
                      <option value="option-4">Place 3</option>
                    </select>
                  </div>

                  <div className="form-group mb-3 col-md-3">
                    <label className="form-label">Telefono movil</label>
                    <input
                      type="number"
                      placeholder="telefono"
                      className="form-control"
                      onChange={(e) =>
                        setClient({ ...client, phone: e.target.value })
                      }
                    />
                  </div>
                </div>

                {/* ==================================================== */}
                {/* ==================================================== */}

                <div className="form-group mb-3">
                  <label className="form-label">Direccion</label>
                  <input
                    type="text"
                    placeholder="1234 Main St"
                    className="form-control"
                    onChange={(e) =>
                      setClient({ ...client, address: e.target.value })
                    }
                  />
                </div>
                {/* ==================================================== */}

                <div className="row">
                  <div className="form-group mb-3 col-md-4">
                    <label className="form-label">Provincia</label>
                    <select
                      className="form-control"
                      id="inputState"
                      defaultValue="option-1"
                      onChange={(e) => {
                        setClient({ ...client, province: e.target.value });
                        setProvince(e.target.value);
                      }}
                    >
                      <option disabled value="option-1">
                        Escoger...
                      </option>
                      {costaRicanProvinces.map((value, index) => (
                        <option key={index} value={value}>
                          {value.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3 col-md-4">
                    <label className="form-label">Canton</label>
                    <select
                      className="form-control"
                      id="inputState"
                      defaultValue="option-1"
                      onChange={(e) => {
                        setClient({ ...client, canton: e.target.value });
                        setCanton(e.target.value);
                      }}
                    >
                      <option disabled value="option-1">Escoger...</option>
                      {costaRicanCantons[province]?.map(
                        (val, id) => (
                          <option key={id} value={val.name}>
                            {val.name}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className="form-group mb-3 col-md-4">
                    <label className="form-label">Distrito</label>
                    <select
                      className="form-control"
                      id="inputState"
                      defaultValue="option-1"
                      onChange={(e) => {
                        setClient({ ...client, district: e.target.value });
                      }}
                    >
                      <option disabled value="option-1">Escoger...</option>
                      {costaRicanCantons[province]?.find(v=>v.name==canton)?.districts?.map(
                        (val, id) => (
                          <option key={id} value={val}>
                            {val}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                  <div className="form-group mb-3 col-md-3">
                    <label className="form-label">Codigo Postal</label>
                    <input
                      type="number"
                      placeholder="Codigo postal"
                      className="form-control"
                      onChange={(e) => {setClient({ ...client, zipCode: e.target.value }); }}
                    />
                  </div>

                  <div className="form-group mb-3 col-md-3">
                    <label className="form-label">Ocupacion</label>
                    <input
                      type="text"
                      placeholder="Ocupacion"
                      className="form-control"
                      onChange={(e) => {setClient({ ...client, occupation: e.target.value }); }}
                    />
                  </div>

                  <div className="form-group mb-3 col-md-6">
                    <label className="form-label">Correo electronico</label>
                    <input
                      type="email"
                      placeholder="correo electronico"
                      className="form-control"
                      onChange={(e) => {setClient({ ...client, email: e.target.value }); }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* ======================================================================== */}
        {/* Configuracion de la cuenta */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger light" onClick={() => setShowModal(false)}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onHandleAddClient();
            setShowModal(false);
          }}
        >
          Registrar CLiente
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddClientModal;
